import { useState, useRef, useEffect } from 'react';
import {
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  InputAdornment,
  Chip,
  IconButton,
  Popper,
  Fade,
  Snackbar,
  Alert,
} from '@mui/material';
import { CloseIcon, SearchIcon } from '@iconicicons/react';
import { useMapUtils } from '../../../MapUtils';
import Recommendations from './Recommendations';
import { BasicButton } from '../../../../atoms/Button/index';
import CategorySelector from '../../../Recommendations/CategorySelector';
import generateSuggestions from './api';
import recommendationCategories from '../../../../../assets/newRecommendationCategories.json';
import { Sparkles } from '../../../../atoms/Icon';
import actions from '../../../../../redux/actions';
import { EVENTS, phTrackEvent } from '../../../../../analytics';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  snackBar: {
    position: 'absolute',
    top: -50,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',
  },
  headingContainer: {
    fontSize: '14px',
    fontWeight: 500,
  },
  searchField: {
    width: '100%',
    backgroundColor: '#f4f4f4',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
  listItemContainer: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      color: '#000000',
    },
  },
  listItemText: {
    fontWeight: 300,
    '&:hover': {
      color: '#000000',
    },
  },
  searchIcon: {
    height: '30px',
    width: '30px',
    color: '#aaaaaa',
  },
  expandIcon: {
    height: 24,
    width: 24,
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    '&:active': {
      backgroundColor: '#d9d9d9',
    },
  },
});

function SuggestActivities({
  tripId,
  handleButtonClick,
  activeLocationId,
  locations,
  setActiveSection,
  setValues,
  setSuggestion,
}) {
  const { focusPlace } = useMapUtils();
  const dispatch = useDispatch();

  const mapPins = useSelector((state) => state.Map.mapPins[tripId]);
  const isExpanded = useSelector(
    (state) => state.View.commandBar.isRecommendationsOpen
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [lastPrompt, setLastPrompt] = useState('');
  const [userPrompts, setUserPrompts] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);
  const catButtonRef = useRef(null);
  const [data, setData] = useState([]);
  const [showActivityCard, setShowActivityCard] = useState(false);
  const [existingResults, setExistingResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  // Keyboard event listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && anchorEl) {
        setAnchorEl(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [anchorEl, handleButtonClick]);

  const setRecommendationMenu = (isRecommendationsOpen) => {
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen,
      })
    );
  };

  const appendPlacesDataToExistingResults = (placesDataArray) => {
    if (placesDataArray.length === 0) return;
    const newPlaces = placesDataArray.map((place) => ({
      pinName: place?.placesData?.pinName,
      placeId: place?.placesData?.place_id,
      coordinates: {
        lat: place?.placesData?.geometry?.location?.lat,
        long: place?.placesData?.geometry?.location?.lng,
      },
    }));

    setExistingResults((prevResults) => [...prevResults, ...newPlaces]);
  };

  const computeCoordinates = (location) => {
    if (location?.mapPin && mapPins[location?.mapPin]) {
      return {
        lat: mapPins[location?.mapPin].lat,
        long: mapPins[location?.mapPin].long,
      };
    }
    return null;
  };

  const handleSuggestActivities = async () => {
    // Don't proceed if prompt is empty or interest aren't selected
    if (prompt.trim().length === 0 && filterLabels.length === 0) return;
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: true,
      })
    );
    setLoading(true);
    setShowActivityCard(true);
    setConversation((prev) => [...prev, { type: 'prompt', content: prompt }]);
    // set the filter labels if any
    if (filterLabels.length > 0) {
      setConversation((prev) => [
        ...prev,
        { type: 'filterLabels', content: filterLabels },
      ]);
    }
    const coordinates = computeCoordinates(locations[activeLocationId]);
    const userPrompt = prompt;
    setPrompt('');
    const res = await generateSuggestions({
      userPrompt,
      destinationName: locations[activeLocationId]?.name,
      existingResults,
      interests: filterLabels,
      coordinates,
    });
    if (res && res.length > 0) {
      // last prompt is empty, this is the first prompt
      if (lastPrompt === '') {
        phTrackEvent({
          event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_LOAD_RESULTS,
        });
      } else {
        phTrackEvent({
          event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_LOAD_RESULTS,
        });
      }
      dispatch(actions.Recommendations.setActivities(res));
      focusPlace(
        {
          longitude: res[0]?.long,
          latitude: res[0]?.lat,
          zoom: 8,
        },
        false
      );
    }
    if (!res && lastPrompt === '') {
      phTrackEvent({
        event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_INITIAL_ERROR,
      });
    } else if (!res && lastPrompt !== '') {
      phTrackEvent({
        event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_RESULTS_ERROR,
      });
    }
    // Add the response activities to the conversation
    setConversation((prev) => [...prev, { type: 'activity', content: res }]);
    setLastPrompt(userPrompt);
    setData(res || []);
    setUserPrompts((prevPrompts) => [...prevPrompts, prompt]);
    appendPlacesDataToExistingResults(res || []);
    setLoading(false);
  };

  const handleClick = (event) => {
    setInfoAnchorEl(infoAnchorEl ? null : event.currentTarget);
  };

  const handleSnackBarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const open = Boolean(infoAnchorEl);
  const id = open ? 'suggestions-popper' : undefined;

  return (
    <>
      <Paper
        sx={{
          padding: isExpanded ? '16px' : '12px',
          width: '595px',
          borderRadius: '12px',
          boxShadow: 3,
        }}>
        <Box
          className={classes.headerContainer}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: isExpanded ? '16px' : '0px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Sparkles
              style={{
                height: '35px',
                width: '35px',
                color: !isExpanded ? '#A12BFF' : '#000000',
              }}
            />
            {isExpanded ? (
              <>
                <Typography
                  className={classes.headingContainer}
                  sx={{ marginLeft: 0, fontWeight: 'bold' }}>
                  Suggest
                </Typography>
                <ChevronRight
                  sx={{ height: 16, width: 16, alignItems: 'center' }}
                />
                <Typography
                  className={classes.headingContainer}
                  sx={{ fontWeight: 'bold' }}>
                  {locations[activeLocationId]?.name || 'New Destination'}
                </Typography>
              </>
            ) : (
              <Typography
                sx={{
                  marginLeft: 1,
                  color: '#8A8A8A',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '430px',
                }}>
                {userPrompts.length > 0 && userPrompts[userPrompts.length - 1]}
              </Typography>
            )}
          </Box>

          {!showActivityCard && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                setActiveSection('suggestIdeas');
                setValues('Suggest Ideas ');
                setSuggestion('Activities');
              }}>
              <ChevronLeft
                sx={{
                  height: 16,
                  width: 16,
                  alignItems: 'center',
                  color: 'gray',
                }}
              />
              <Typography sx={{ color: 'gray', fontSize: '14px' }}>
                Back
              </Typography>
            </Box>
          )}

          {showActivityCard &&
            (isExpanded ? (
              <ExpandLess
                className={classes.expandIcon}
                onClick={() => setRecommendationMenu(!isExpanded)}
              />
            ) : (
              <ExpandMore
                className={classes.expandIcon}
                onClick={() => setRecommendationMenu(!isExpanded)}
              />
            ))}
        </Box>

        {showActivityCard ? (
          <>
            <Recommendations
              tripId={tripId}
              activities={data}
              conversation={conversation}
              loading={loading}
              isExpanded={isExpanded}
              lastPrompt={lastPrompt}
              setPrompt={setPrompt}
              setShowActivityCard={setShowActivityCard}
              setToastMessage={setToastMessage}
              setShowSnackBar={setShowSnackBar}
            />
            {isExpanded && (
              <>
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '60px',
                    left: '52%',
                  }}
                  ref={catButtonRef}
                />
                <CategorySelector
                  filterLabels={filterLabels}
                  setFilterLabels={(selected) => {
                    setFilterLabels(selected);
                  }}
                  filterOpen={filterOpen ? catButtonRef.current : null}
                  setFilterOpen={setFilterOpen}
                  isQuickFilter
                  source="subsequent_suggestions"
                />
                <TextField
                  placeholder="type a prompt..."
                  value={prompt}
                  sx={{
                    width: '100%',
                    marginTop: '12px',
                    '& .MuiOutlinedInput-root': {
                      padding: '0px 12px',
                      '&.Mui-focused fieldset': {
                        borderColor: '#c3c3c3',
                        borderWidth: '1px',
                      },
                    },
                  }}
                  autoFocus
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      phTrackEvent({
                        event:
                          EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_SUBMIT,
                      });
                      handleSuggestActivities();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {showActivityCard ? (
                          <ChevronLeft
                            sx={{
                              height: 24,
                              width: 24,
                              cursor: 'pointer',
                              alignItems: 'center',
                              color: '#000000',
                              borderRadius: '8px',
                              '&:hover': {
                                backgroundColor: '#f4f4f4',
                              },
                              '&:active': {
                                backgroundColor: '#d9d9d9',
                              },
                            }}
                            onClick={() => setShowActivityCard(false)}
                          />
                        ) : (
                          <SearchIcon sx={{ width: 30, height: 30 }} />
                        )}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          onClick={() => {
                            phTrackEvent({
                              event:
                                EVENTS.PLAN_UNIVERESAL_ADD
                                  .SUGGEST_SUBSEQUENT_INTEREST_CLICK,
                            });
                            setFilterOpen(!filterOpen);
                          }}
                          sx={{
                            color: '#000000',
                            padding: '0 8px',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}>
                          {`Add Interests ${
                            filterLabels?.length > 0
                              ? `(${filterLabels.length})`
                              : ''
                          }`}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    sx: {
                      padding: '8px',
                    },
                  }}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Box sx={{ marginTop: 2, position: 'relative' }}>
              <TextField
                value={prompt}
                placeholder="The ultimate places only a local would know"
                sx={{
                  display: 'block',
                  flexGrow: 1,
                  outline: 'none',
                  backgroundColor: '#f5f5f5',
                  '& .MuiOutlinedInput-root': {
                    paddingBottom: '50px',
                    '&:hover fieldset': {
                      borderColor: '#d3d3d3',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#d3d3d3',
                    },
                  },
                }}
                multiline
                rows={3}
                maxRows={5}
                fullWidth
                margin="dense"
                autoFocus
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    phTrackEvent({
                      event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_SUBMIT,
                    });
                    handleSuggestActivities();
                  }
                }}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <Box
                sx={{
                  position: 'fixed',
                  bottom: '120px',
                  left: '52%',
                }}
                ref={catButtonRef}
              />

              <BasicButton
                marginRight={1}
                onClick={() => {
                  phTrackEvent({
                    event:
                      EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_INTEREST_CLICK,
                  });
                  setFilterOpen(!filterOpen);
                }}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  bottom: '8px',
                  left: '8px',
                  backgroundColor: '#FFFFFF',
                  color: '#4E4E4E',
                  border: '1px solid #D9D9D9',
                  maxHeight: '32px',
                  margin: '4px',
                  padding: '8px 12px',
                  '&:hover': {
                    border: '1px solid #D9D9D9',
                    backgroundColor: '#F4F4F4',
                    color: '#4E4E4E',
                  },
                }}
                disableRipple>
                Add interests
              </BasicButton>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '4px',
                  left: '150px',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '8px',
                  overflowX: 'auto',
                  width: 'calc(100% - 160px)',
                  paddingBottom: '8px',
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': {
                    height: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#b0b0b0',
                    borderRadius: '4px',
                  },
                }}>
                {filterLabels.map((label, index) => (
                  <Chip
                    key={index}
                    size="medium"
                    label={recommendationCategories.ref[label].name}
                    onDelete={() => {
                      setFilterLabels(
                        filterLabels.filter((item) => item !== label)
                      );
                    }}
                    deleteIcon={<CloseIcon />}
                    sx={{
                      backgroundColor: '#FFFFFF',
                      color: '#4E4E4E',
                      borderRadius: '8px',
                      border: '1px solid #D9D9D9',
                      '&:hover': {
                        backgroundColor: '#F4F4F4',
                        color: '#4E4E4E',
                      },
                    }}
                  />
                ))}
              </Box>

              <CategorySelector
                filterLabels={filterLabels}
                setFilterLabels={(selected) => {
                  setFilterLabels(selected);
                }}
                filterOpen={filterOpen ? catButtonRef.current : null}
                setFilterOpen={setFilterOpen}
                isQuickFilter
                source="suggestions"
              />
            </Box>

            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'gray',
                fontSize: '12px',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <IconButton
                  sx={{
                    fontSize: '14px',
                    borderRadius: '12px',
                    padding: '6px',
                    transform: 'rotate(180deg)',
                    bgcolor: infoAnchorEl ? '#F4F4F4' : 'transparent',
                    '&:hover': {
                      color: 'none',
                    },
                  }}
                  aria-describedby={id}
                  onClick={handleClick}>
                  <ErrorOutline sx={{ color: '#8A8A8A' }} />
                </IconButton>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={infoAnchorEl}
                  placement="top-start"
                  transition
                  sx={{ zIndex: 1122 }}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper
                        sx={{
                          padding: '8px',
                          maxWidth: '430px',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <ErrorOutline
                          sx={{
                            marginRight: '12px',
                            color: '#8A8A8A',
                            transform: 'rotate(180deg)',
                          }}
                        />
                        <Typography sx={{ fontSize: '12px' }}>
                          With each use of the Suggest feature, the aggregated
                          data is constantly being improved. We acknowledge at
                          this time it is impossible to guarantee real-time
                          accuracy. We recommend verifying details based on the
                          suggestions presented prior to your travels.
                        </Typography>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </Box>
              <Button
                variant="contained"
                sx={{
                  color:
                    prompt.length > 0 || filterLabels.length > 0
                      ? '#A12BFF'
                      : '#000000',
                  boxShadow: 'none',
                  border: 'none',
                  bgcolor:
                    prompt.length > 0 || filterLabels.length > 0
                      ? '#e5c5ff'
                      : '#E3E3E3',
                  fontSize: '13px',
                  padding: '2px 12px',
                  '& .MuiButton-startIcon': {
                    marginRight: '0px',
                  },
                  '&:hover': {
                    bgcolor:
                      prompt.length > 0 || filterLabels.length > 0
                        ? '#e5c5ff'
                        : '#E3E3E3',
                    boxShadow: 'none',
                    color:
                      prompt.length > 0 || filterLabels.length > 0
                        ? '#A12BFF'
                        : '#000000',
                  },
                }}
                startIcon={
                  <Sparkles
                    style={{
                      width: '32px',
                      height: '32px',
                    }}
                  />
                }
                onClick={() => {
                  phTrackEvent({
                    event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_SUBMIT,
                  });
                  handleSuggestActivities();
                }}>
                Suggest
              </Button>
            </Box>
          </>
        )}
      </Paper>
      <Snackbar
        className={classes.snackBar}
        open={showSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          maxWidth: '600px',
          width: '100%',
        }}>
        <Alert
          severity="success"
          variant="filled"
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '8px',
            paddingLeft: '8px',
            paddingTop: '1px',
            paddingBottom: '1px',
          }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
            }}>
            {toastMessage}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default SuggestActivities;
