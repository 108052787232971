import { useFeatureFlagEnabled } from 'posthog-js/react';
import FLAGS from '../../../../../featureFlags';
import HotelDetailsPageV1 from './HotelDetailsPage';
import HotelDetailsPageV2 from './HotelDetailsPageV2';

function HotelDetailsPage(props) {
  const shouldShowV2 = useFeatureFlagEnabled(FLAGS.PILOT_BOOKINGS);
  return shouldShowV2 ? (
    <HotelDetailsPageV2 {...props} />
  ) : (
    <HotelDetailsPageV1 {...props} />
  );
}

export default HotelDetailsPage;
