import { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Popper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Divider,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  ArrowDropDownRounded,
  ChevronRightRounded,
  AddCircleOutlineRounded,
  StarRounded,
  LanguageOutlined,
} from '@mui/icons-material';
import { formatNumber } from '../../../../../utils';
import {
  ButtonDefault,
  SecondaryOutlinedButton,
} from '../../../../atoms/Button/index';
import { useMapUtils } from '../../../MapUtils';
import actions from '../../../../../redux/actions';
import { createLocation } from '../../../../../redux/slices/Location';
import {
  createActivity,
  createNewActivityInLocation,
} from '../../../../../redux/slices/Activity';
import config from '../../../../config';
import { EVENTS, phTrackEvent } from '../../../../../analytics';
import { GoogleMapsIcon } from '../../../../atoms/Icon';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    height: '160px',
    width: '550px',
    border: '1px solid #D9D9D9',
    boxShadow: 'none',
    marginBottom: 16,
  },
  image: {
    width: 140,
    height: 'auto',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headingContainer: {
    fontSize: '14px',
    fontWeight: 500,
  },
  description: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    height: 28,
    fontSize: '12px',
    padding: '8px 8px',
    borderRadius: '4px',
  },
  popper: {
    zIndex: 1121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
});

function ActivityCard({
  description,
  placesData,
  tripId,
  setToastMessage,
  setShowSnackBar,
}) {
  const { getPlaceDetails, createMapPinForPlace, extractAddressComponents } =
    useMapUtils();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentTrip = useSelector((state) => state.Trips.trips[tripId]);
  const locationIds = currentTrip?.items
    ?.filter((item) => item.location !== null)
    ?.map((item) => item.location);
  const locations = useSelector((state) => state.Location.locations);
  const thingsToDo = useSelector((state) => state.Section.sections);
  const commandBarState = useSelector((state) => state.View.commandBar);
  const ref = useRef(null);
  const hoverTimeRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [locationPopperOpen, setLocationPopperOpen] = useState(false);
  const [locationAnchor, setLocationAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [mapData, setMapData] = useState(null);
  const { activeLocationId, activeSectionId } = commandBarState;

  useEffect(async () => {
    const { maps } = await getPlaceDetails(
      placesData?.place_id,
      'ACTIVITY',
      true
    );
    setMapData(maps);
  }, []);

  const fallBackUrl = '/images/DefaultMapPopupBanner.png';
  const [imgUrl, setImgUrl] = useState(fallBackUrl);

  const activityImg = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${placesData?.photoRef}&key=${config.googlePlacesKey}`;

  const img = new Image();
  img.src = activityImg;
  img.onload = () => {
    if (img.height === 100 && img.width === 100) {
      setImgUrl(fallBackUrl);
    } else {
      setImgUrl(activityImg);
    }
  };

  const handleSeeFullDetails = async () => {
    phTrackEvent({
      event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_SEE_DETAIL_CLICK,
    });
    const { title } = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    phTrackEvent({
      event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_SEE_DETAIL_CLICK_ACTIVITY}${title}`,
    });
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: false,
      })
    );
  };

  const handleDropDownClick = (e) => {
    e.stopPropagation();
    setAnchorEl(ref?.current);
    setOpen(!open);
  };

  const handleAddLocation = async () => {
    const details = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: false,
      })
    );
    const mapPinId = await createMapPinForPlace(details, 'LOCATION');
    await dispatch(
      createLocation({
        variables: {
          name: details.title,
          tripID: tripId,
          mapPin: mapPinId,
          index: 0,
        },
      })
    );
  };

  const handleAddActivity = async (
    locationId,
    sectionId,
    sectionName,
    locationName
  ) => {
    const {
      title,
      photo,
      rating,
      website,
      maps,
      ratingCount,
      long,
      lat,
      types,
      categoryId,
      openingHoursObj,
      addressComponents,
      formattedAddress,
      formattedPhoneNumber,
    } = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: false,
      })
    );
    phTrackEvent({
      event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_MANUAL_ADD,
    });
    phTrackEvent({
      event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_MANUAL_ADD_ACTIVITY}${title}`,
    });
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    const mapPin = await createMapPinForPlace(
      {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
        placeId: placesData?.place_id,
        categoryId,
      },
      'ACTIVITY',
      {
        openingHoursObj,
        addressComponents,
        formattedAddress,
        formattedPhoneNumber,
        maps,
        website,
      }
    );
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    if (sectionId === null) {
      await dispatch(
        createNewActivityInLocation({
          locationId,
          activityTitle: title,
          mapPin: mapPin?.id,
          ...address,
        })
      );
      setToastMessage(`Added to ${locationName}`);
      setShowSnackBar(true);
    } else {
      await dispatch(
        createActivity({
          variables: {
            title,
            mapPin: mapPin?.id,
            thingsToDoId: sectionId,
            index: thingsToDo[sectionId]?.todos?.length,
            ...address,
          },
          sectionId,
          index: thingsToDo[sectionId]?.todos?.length,
        })
      );
      if (sectionName) {
        setToastMessage(`Added to ${locationName} > ${sectionName}`);
      } else {
        setToastMessage(`Added to ${locationName}`);
      }
      setShowSnackBar(true);
    }
  };

  const handleDropDownHover = (e) => {
    e.stopPropagation();
    clearTimeout(hoverTimeRef.current);
    if (!open) {
      setAnchorEl(ref?.current);
      setOpen(true);
    }
  };

  const formatString = (str) => {
    return str
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleDropDownLeave = (e) => {
    e.stopPropagation();
    // Adding delay to avoid flickering
    hoverTimeRef.current = setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
      setLocationPopperOpen(null);
    }, 300);
  };

  const popperId = open ? 'search-location-popper' : undefined;

  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
  };

  const renderLocations = () =>
    locationIds &&
    locationIds.map((locationId, index) => {
      const location = locations[locationId];
      if (!location) return;
      const { id, name } = location;
      const handleLocationHover = (e) => {
        const element = document.getElementById(
          `scrollable-container-${index}`
        );
        if (element) {
          dispatch(
            actions.View.setHighlightedSection({
              section: index,
            })
          );
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        if (locationPopperOpen === id) return;
        setLocationPopperOpen(id);
        setLocationAnchor(e.currentTarget);
      };
      const handleSectionHover = (e, highLightedsectionId) => {
        e.stopPropagation();
        dispatch(
          actions.View.setHighlightedSection({
            section: index,
          })
        );
        const element = document.getElementById(
          `todo-header-${highLightedsectionId}`
        );
        if (element) {
          dispatch(
            actions.View.setHighlightedHeading({
              section: highLightedsectionId,
            })
          );
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      };

      const sections =
        location?.thingsToDo?.map((thingsToDoId) => thingsToDo[thingsToDoId]) ||
        [];

      // eslint-disable-next-line consistent-return
      return (
        <ListItem
          key={id}
          onClick={() => {
            if (sections.length === 0) {
              handleAddActivity(id, null, null, name);
              handleClickAway();
            }
          }}
          id="add-to-trip-button"
          onMouseOver={handleLocationHover}
          onMouseLeave={() => {
            if (sections?.length === 0) {
              setLocationPopperOpen(null);
            }
            dispatch(
              actions.View.setHighlightedSection({
                section: null,
              })
            );
          }}
          className={classes.listItem}
          style={{
            // to align the text, 12px(specified) + 2px(alignment spacing) with the 'new location' icon according to the designs
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemIcon
            style={{
              minWidth: 0,
              marginRight: 4,
            }}>
            <LanguageOutlined
              style={{
                height: 14,
                width: 14,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {sections.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ChevronRightRounded
                  style={{
                    height: 14,
                    width: 14,
                    ...(locationPopperOpen === id
                      ? {}
                      : { color: 'transparent' }),
                  }}
                />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={locationPopperOpen === id}
                anchorEl={locationAnchor}
                placement="right"
                style={{
                  width: 'inherit',
                  zIndex: 1122,
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 8],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <List
                    className={classes.list}
                    style={{ maxHeight: 100, maxWidth: 120 }}
                    onMouseLeave={() => {
                      setLocationPopperOpen(null);
                    }}>
                    {sections?.map((section) => {
                      return (
                        <ListItem
                          key={section.id}
                          onClick={() => {
                            handleAddActivity(
                              id,
                              section?.id,
                              section?.name,
                              name
                            );
                            handleClickAway();
                          }}
                          onMouseOver={(e) => {
                            handleSectionHover(e, section?.id);
                            handleDropDownHover(e);
                          }}
                          onMouseLeave={(e) => {
                            dispatch(
                              actions.View.setHighlightedHeading({
                                section: null,
                              })
                            );
                            dispatch(
                              actions.View.setHighlightedSection({
                                section: null,
                              })
                            );
                            handleDropDownLeave(e);
                          }}
                          className={classes.listItem}
                          justify="center"
                          style={{ padding: '2px 12px' }}>
                          <ListItemText
                            primary={
                              section.name === ''
                                ? 'Unnamed Section'
                                : section?.name
                            }
                            primaryTypographyProps={{ fontSize: '14px' }}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    });

  return (
    <>
      <Card className={classes.card}>
        <CardMedia
          component="img"
          image={imgUrl || fallBackUrl}
          alt={placesData?.pinName}
          className={classes.image}
        />
        <CardContent className={classes.content}>
          <Typography className={classes.headingContainer}>
            {placesData?.pinName}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {placesData?.types && formatString(placesData?.types[0])}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}>
            {description}
          </Typography>
          <Box className={classes.buttons}>
            <Box className={classes.rating}>
              {mapData && (
                <IconButton
                  sx={{ padding: 0, marginRight: '4px' }}
                  onClick={() => window.open(mapData, '_blank')}
                  disableFocusRipple
                  disableRipple>
                  <GoogleMapsIcon />
                </IconButton>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                sx={{ display: 'flex', alignItems: 'center' }}>
                <StarRounded
                  sx={{
                    verticalAlign: 'middle',
                    color: '#ED702E',
                    height: 20,
                    width: 20,
                  }}
                />
                {placesData?.rating} (
                {formatNumber(placesData?.user_ratings_total || 0)})
              </Typography>
            </Box>
            <Box>
              <SecondaryOutlinedButton
                className={classes.buttonContainer}
                onClick={handleSeeFullDetails}
                sx={{
                  marginRight: '3px',
                  '&:hover': {
                    backgroundColor: '#D9D9D9',
                    borderColor: '#D9D9D9',
                    color: 'inherit',
                    '& .MuiButton-startIcon': {
                      '& svg': {
                        color: 'inherit',
                      },
                    },
                  },
                }}>
                See details
              </SecondaryOutlinedButton>
              <ButtonGroup
                sx={{
                  boxShadow: 'none',
                  '& .MuiButtonGroup-grouped': {
                    minWidth: 0,
                  },
                }}>
                <ButtonDefault
                  className={classes.buttonContainer}
                  disableFocusRipple
                  disableRipple
                  onClick={() => {
                    if (!activeLocationId) {
                      handleAddLocation();
                    } else {
                      handleAddActivity(
                        activeLocationId,
                        activeSectionId,
                        thingsToDo[activeSectionId]?.name,
                        locations[activeLocationId]?.name
                      );
                    }
                  }}
                  sx={{
                    '& .MuiButton-endIcon': {
                      margin: '0px !important',
                      padding: '0px !important',
                    },
                  }}>
                  Add to trip
                </ButtonDefault>
                <ButtonDefault
                  ref={ref}
                  onClick={handleDropDownClick}
                  size="small"
                  sx={{
                    height: 28,
                    fontSize: '12px',
                    padding: '2px 2px',
                    borderRadius: '4px',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: '0',
                      top: '30%',
                      height: '40%',
                      width: '1px',
                      backgroundColor: 'white',
                    },
                  }}>
                  <ArrowDropDownRounded sx={{ padding: 0, margin: 0 }} />
                </ButtonDefault>
              </ButtonGroup>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Popper
        id={popperId}
        open={open}
        placement="right"
        anchorEl={anchorEl}
        className={classes.popper}
        onMouseOver={handleDropDownHover}
        onMouseLeave={handleDropDownLeave}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List className={classes.list}>
            <ListItem
              key="create-new-location"
              onClick={async () => {
                await handleAddLocation();
                handleClickAway();
              }}
              onMouseOver={() => {
                setLocationPopperOpen('create-new-location');
              }}
              className={classes.listItem}
              style={{
                whiteSpace: 'nowrap',
                justifyContent: 'flex-start',
              }}>
              <ListItemIcon
                style={{
                  minWidth: 0,
                  marginRight: 4,
                }}>
                <AddCircleOutlineRounded
                  style={{
                    height: 14,
                    width: 14,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="New Location"
                primaryTypographyProps={{
                  fontSize: '14px',
                }}
              />
            </ListItem>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
            {renderLocations()}
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default ActivityCard;
