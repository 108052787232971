import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import TripCard from '../../molecules/CreateATripFlow/TripCard';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import CitiesList from '../../../assets/citiesList';

function SearchSuggestionSkeleton() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        borderBottom: '1px solid #E0E0E0',
        paddingBottom: 8,
      }}>
      <div style={{ height: 50 }}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={35}
          height={35}
          style={{
            marginRight: 10,
            marginTop: 5,
          }}
        />
      </div>
      <div>
        <Skeleton
          animation="wave"
          width={130}
          height={25}
          style={{ borderRadius: 8 }}
        />
        <Skeleton
          animation="wave"
          width={175}
          height={25}
          style={{ borderRadius: 8 }}
        />
      </div>
    </div>
  );
}

function Step1Page({
  setStep,
  tripImageURL,
  tripName,
  dateRange,
  locationText,
  setLocationText,
  location,
  setLocation,
  handleLocationAdd,
  tripUsers,
  cancel,
}) {
  const bladeSearchRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [focus, setFocus] = useState();

  useEffect(() => {
    if (bladeSearchRef?.current) {
      bladeSearchRef.current.focus();
    }
  }, [bladeSearchRef.current]);

  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
      }}>
      <Grid
        container
        height="450px"
        sx={{ paddingX: { xs: '20px', sm: '100px' } }}>
        {isMobile && (
          <Box sx={{ width: '100%', textAlign: 'end' }}>
            <CloseRounded
              sx={{
                fill: '#8A8A8A',
              }}
              onClick={cancel}
            />
          </Box>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Stack maxWidth="400px" spacing={3} mt="30px">
            <Typography color="#8A8A8A" letterSpacing="3px">
              WELCOME TO PILOT:
            </Typography>

            <Typography sx={{ fontSize: '37px', fontWeight: 500 }}>
              What's your first destination?
            </Typography>

            <PlacesSearchBar
              autoSearch
              openOnFocus
              isCitySearch
              showBackdrop
              enterToSelect
              tripLocation={false}
              disableLocationBias
              placeholder="Name your activity..."
              popperPlacement="bottom"
              CustomSearchBar={TextField}
              inputRef={bladeSearchRef}
              locationBias={null}
              searchBarProps={{
                fullWidth: true,
                placeholder: 'Enter text',
                variant: 'standard',
                inputProps: {
                  style: {
                    padding: 3,
                    marginLeft: '8px',
                  },
                },
                onFocus: () => {
                  setFocus(true);
                },
                onBlur: () => {
                  setFocus(false);
                },
              }}
              handleSelect={(selectedLocation) => {
                setLocation(selectedLocation);
                setFocus(false);
              }}
              value={locationText}
              controlledValue={locationText}
              onChange={setLocationText}
              defaultPopperComponent={
                focus &&
                locationText.length && (
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      padding: 12,
                      paddingBottom: 0,
                      borderRadius: '4px',
                      minWidth: bladeSearchRef?.current?.offsetWidth,
                      zIndex: 1122,
                      boxShadow:
                        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                    }}>
                    {Array.from(Array(3).keys()).map((index) => (
                      <SearchSuggestionSkeleton
                        key={`search-skeleton-${index}`}
                      />
                    ))}
                  </div>
                )
              }
            />

            <Button
              sx={{
                backgroundColor: 'white',
                border: '#d9d9d9 1px solid',
                color: 'black',
                transitionDelay: '0',
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'primary.main',
                  borderColor: 'primary.main',
                  transitionDelay: '0',
                },
                width: 'fit-content',
              }}
              onClick={() => {
                const cityName =
                  CitiesList[Math.floor(Math.random() * CitiesList.length)];
                setLocationText(cityName);
                bladeSearchRef?.current?.focus();
              }}>
              Suggest Destination
            </Button>

            {isMobile && (
              <Button
                fullWidth
                disabled={!location}
                onClick={() => {
                  handleLocationAdd();
                  setStep((prev) => prev + 1);
                }}>
                Next
              </Button>
            )}
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                maxWidth: '400px',
              }}>
              <Box mb={8}>
                <TripCard
                  tripImage={
                    tripImageURL || '/images/blank-trip-placeholder.png'
                  }
                  tripName={tripName}
                  dateRange={dateRange}
                  tripUsers={tripUsers}
                />
              </Box>

              <Button
                fullWidth
                disabled={!location}
                onClick={() => {
                  handleLocationAdd();
                  setStep((prev) => prev + 1);
                }}>
                Next
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Step1Page;
