import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Typography,
  Popper,
  List,
  ListItem,
  Tooltip,
  Skeleton,
  Snackbar,
  Alert,
  CardContent,
  Box,
  IconButton,
  Collapse,
  Portal,
  //   Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Close,
  CheckCircleOutline,
  CancelOutlined,
  StarRounded,
} from '@mui/icons-material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import FLAGS from '../../../../featureFlags';
import { useSounds, SOUNDS } from '../../../../sounds';
import {
  ArrowIcon,
  CallIcon,
  ClockIcon,
  GoogleMapsIcon,
  LocationPinIconFilled,
} from '../../../atoms/Icon';
import {
  createActivity,
  createNewActivityInLocation,
  deleteActivity,
} from '../../../../redux/slices/Activity';
import {
  LocationActions,
  deleteLocation,
} from '../../../../redux/slices/Location';
import { trackEvents, Events } from '../../../../intercom';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import PinToMapIcon from '../../PinToMapIcon';
import { useMapUtils } from '../../../organisms/MapUtils';
import {
  formatNumber,
  getPlaceTypeFromTypes,
  updateSessionStorageForLastEditedSection,
} from '../../../../utils';
import AddToTripButtonNew from '../../AddToTripButton/AddToTripButtonNew';
import actions from '../../../../redux/actions';
import Carousel from '../../Carousel';
import { DeleteModal } from '../../Modal';
import useTour from '../../Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';

const useCustomCardStyles = makeStyles(({ palette }) => ({
  hoverContent: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.2s ease-in-out, opacity 0.2s ease-in-out',
  },
  snackBar: {
    position: 'absolute',
    top: (props) => (props.isAiMenuOpen ? -132 : -60),
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',
  },
  description: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    '-webkit-box-pack': 'end',
  },
  addressComponent: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: (props) =>
      props.openingHoursObj || props.formattedPhoneNumber ? '170px' : 'auto',
  },
  detailedAddressComponent: {
    fontSize: 12,
    lineHeight: '140%',
    maxHeight: 'calc(22.4px * 3)',
    fontWeight: 400,
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  },
  flexLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    marginBottom: 4,
  },
  title: {
    marginBottom: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-flex',
    marginRight: '4%',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 3,
    backgroundColor: '#FFF',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    width: '25px',
    height: '25px',
    '&:hover': {
      color: '#f4f4f4',
      backgroundColor: '#f4f4f4',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    height: '100%',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  reviews: {
    color: '#1877F2',
    fontSize: '0.8rem',
    marginLeft: '5%',
  },
  nullReviews: {
    color: '#8A8A8A',
    fontStyle: 'italic',
    marginLeft: '5%',
  },
  defaultStartIcon: {
    '& .MuiButton-startIcon': {
      margin: '0px 4px 0px 0px',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  detailsBlock: {
    display: 'flex',
  },
  detailsBlockIcon: {
    flexShrink: 0,
    marginRight: 6,
  },
  endContainer: {
    flexShrink: 1,
    display: 'flex',
    marginTop: 8,
    justifyContent: 'space-between',
  },
  toggleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  toggleExpand: {
    color: '#686868',
    textDecoration: 'underline',
    fontSize: '0.8rem',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  datePopper: {
    zIndex: 1121,
  },
  dateList: {
    backgroundColor: '#FFF',
    borderRadius: 4,
    boxShadow: '0 0 0 1px #DEDDDD',
  },
  dateListItem: {
    fontSize: 12,
    padding: '2px 12px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#222222',
    },
    '& .active': {
      backgroundColor: '#EAEAEA',
      fontWeight: 'bold',
      color: '#222222',
    },
    '& > span': {
      '&:nth-child(1)': {
        minWidth: 80,
      },
    },
  },
  dateListItemActive: {
    fontSize: 12,
    padding: '2px 12px',
    backgroundColor: '#EAEAEA',
    fontWeight: 'bold',
    color: '#222222',
    '&:hover': {
      backgroundColor: '#EAEAEA',
      color: '#222222',
    },
    '& > span': {
      '&:nth-child(1)': {
        minWidth: 80,
      },
    },
  },
  placeType: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tooltip: {
    background: 'rgba(0,0,0,.8)',
    fontSize: 12,
  },
  tooltipArrow: {
    color: 'rgba(0,0,0,.8)',
  },
  tooltipDisabled: {
    display: 'none',
  },
}));

function MapPopupCardNew({
  placeId,
  photo,
  photos,
  title,
  rating,
  description = '',
  website,
  maps,
  ratingCount,
  addressComponents,
  formattedAddress,
  formattedPhoneNumber,
  handleClose,
  tripId,
  lat,
  long,
  pinned,
  pinId,
  pins,
  type,
  types,
  categoryId,
  pinColor,
  openingHoursObj,
  handleImageError,
  initAction = () => {},
  postAction = () => {},
  setLoader = () => {},
}) {
  const [datePopperOpen, setDatePopperOpen] = useState(false);
  const [dateAnchor, setDateAnchor] = useState(null);
  const [currPlaceId, setCurrPlaceId] = useState(false);
  const [activeCardDetails, setActiveCardDetails] = useState({});
  const [cardLoading, setCardLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [lastAddedActivity, setLastAddedActivity] = useState({});
  const [destinationDelete, setDestinationDelete] = useState(false);
  const [activityDelete, setActivityDelete] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [portalContainer, setPortalContainer] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showIconButton, setShowIconButton] = useState(false);
  const [showCarouselArrows, setShowCarouselArrows] = useState(false);

  const destinationDeletetimeoutRef = useRef(null);
  const deletedDestinationIdRef = useRef(null);
  const deletedDestinationMapPinRef = useRef(null);

  const datePopperId = datePopperOpen ? 'date-popper' : undefined;

  const isCommandBarEnabled = useFeatureFlagEnabled(FLAGS.UNIVERSAL_ADD_BAR);

  const { playSound } = useSounds();
  const dispatch = useDispatch();

  const tripMapPins = useSelector(
    (state) => state.Trips.trips[tripId]?.mapPins || []
  );
  const mapPins = useSelector((state) => state.Map.mapPins[tripId] || []);
  const activities = useSelector((state) => state.Activity.todos || {});
  const thingsToDo = useSelector((state) => state.Section.sections);
  const locations = useSelector((state) => state.Location.locations);
  const { isAiMenuOpen } = useSelector((state) => state.View.commandBar);
  const classes = useCustomCardStyles({
    isAiMenuOpen,
    openingHoursObj,
    formattedPhoneNumber,
  });

  const { tourInProgress, tourName, getTour } = useTour();

  const computeActiveCardProps = (cardPlaceId) => {
    const activeCardItems = {};
    const foundPin = Object.values(mapPins || {}).find(
      (pin) => pin.placeId === cardPlaceId
    );
    activeCardItems.mapPin = foundPin?.id ?? null;
    if (activeCardItems?.mapPin) {
      const foundTodo = Object.values(activities || {}).find(
        (activity) => activity.mapPin === activeCardItems?.mapPin
      );
      activeCardItems.toDoId = foundTodo?.id ?? null;
      activeCardItems.toDoName = foundTodo?.title ?? null;

      if (activeCardItems?.toDoId) {
        const foundSection = Object.values(thingsToDo || {}).find((section) =>
          section.todos.includes(activeCardItems?.toDoId)
        );
        activeCardItems.sectionId = foundSection?.id ?? null;
        activeCardItems.sectionName = foundSection?.name ?? null;
      }
      // search mapPin under locations
      const foundLocation = Object.values(locations || {}).find(
        (location) => location.mapPin === activeCardItems?.mapPin
      );
      if (foundLocation) {
        activeCardItems.locationId = foundLocation?.id ?? null;
        activeCardItems.locationName = foundLocation?.name ?? null;
      } else {
        // get the parent location name and Id using the mapPinOfActiveCard
        const parentLocation = Object.values(locations || {}).find(
          (location) => {
            if (
              location &&
              location.thingsToDo &&
              location.thingsToDo.length > 0
            ) {
              return location.thingsToDo.includes(activeCardItems?.sectionId);
            }
            return false;
          }
        );
        activeCardItems.parentLocationId = parentLocation?.id ?? null;
        activeCardItems.parentLocationName = parentLocation?.name ?? null;
      }
    }
    setActiveCardDetails({ ...activeCardItems });
  };

  useEffect(() => {
    computeActiveCardProps(placeId);
  }, [placeId, mapPins, activities, thingsToDo, locations]);

  useEffect(() => {
    const container = document.getElementById('snackbar-container');
    setPortalContainer(container);
  }, []);
  useEffect(() => {
    getTour()
      .onActiveStep(stepName.ADD_TO_TRIP_BUTTON)
      .openTour({ delay: 1500 });
  }, [tourInProgress, tourName]);
  const handleDateHover = (e) => {
    if (datePopperOpen) return;
    setDatePopperOpen(true);
    setDateAnchor(e.currentTarget);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const deleteDestinationFromItinerary = async () => {
    if (
      deletedDestinationIdRef.current &&
      deletedDestinationMapPinRef.current
    ) {
      await dispatch(
        deleteLocation({
          variables: {
            id: deletedDestinationIdRef.current,
            tripId,
          },
          mapPin: deletedDestinationMapPinRef.current,
        })
      );
    }
  };

  const handleDeleteMapPin = async () => {
    if (activeCardDetails?.locationId) {
      // if adestinationDeletetimeoutRef already exits then immediately delete the previous destination
      if (destinationDeletetimeoutRef.current) {
        setShowSnackBar(false);
        await deleteDestinationFromItinerary();
        clearTimeout(destinationDeletetimeoutRef.current);
        destinationDeletetimeoutRef.current = null;
      }
      dispatch(
        LocationActions.removeLocationSectionTemp({
          locationId: activeCardDetails?.locationId,
        })
      );
      deletedDestinationIdRef.current = activeCardDetails?.locationId;
      deletedDestinationMapPinRef.current = activeCardDetails?.mapPin;
      destinationDeletetimeoutRef.current = setTimeout(() => {
        deleteDestinationFromItinerary();
      }, 5000);
      setDestinationDelete(true);
      setDeleteModalOpen(false);
    } else {
      await dispatch(
        deleteActivity({
          variables: {
            id: activeCardDetails?.toDoId,
            thingsToDoId: activeCardDetails?.sectionId,
          },
          mapPin: activeCardDetails?.mapPin,
          sectionId: activeCardDetails?.sectionId,
        })
      );
      setActivityDelete(true);
      // Update the sessionStorage for the lastUpdatedSectionDetails
      updateSessionStorageForLastEditedSection(
        activeCardDetails?.parentLocationId,
        activeCardDetails?.sectionId
      );
    }
    setLastAddedActivity({
      activityTitle: title,
      locationId:
        activeCardDetails?.locationId || activeCardDetails?.parentLocationId,
      sectionId: activeCardDetails?.sectionId,
      sectionName: activeCardDetails?.sectionName,
      locationName:
        activeCardDetails?.locationName ||
        activeCardDetails?.parentLocationName,
    });
    if (activeCardDetails?.toDoName && activeCardDetails?.sectionName) {
      setToastMessage(
        `Deleted ${activeCardDetails?.toDoName} from ${activeCardDetails?.sectionName}`
      );
    } else {
      setToastMessage(`Deleted ${title}`);
    }
    setShowSnackBar(true);
  };

  // TODO: Add placeType
  const placeType = getPlaceTypeFromTypes(types);

  function TextTooltipElement(props) {
    const thisRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const onHover = () => {
      if (thisRef.current.offsetWidth < thisRef.current.scrollWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };
    return (
      <Tooltip
        title={props.title}
        placement="top"
        classes={{
          tooltip: showTooltip ? classes.tooltip : classes.tooltipDisabled,
        }}
        enterDelay={600}
        leaveDelay={0}
        className="dynamic-text"
        ref={thisRef}
        onMouseOver={onHover}>
        {props.children}
      </Tooltip>
    );
  }

  const renderOpeningDays = () => {
    const day = new Date().getDay();
    const getClassName = (index) => {
      if (day === index + 1 && day !== 0) {
        return classes.dateListItemActive;
      }
      if (day === 0 && index === 6) {
        return classes.dateListItemActive;
      }
      return classes.dateListItem;
    };
    if (openingHoursObj) {
      return openingHoursObj.weekday_text.map((str, index) => (
        <ListItem className={getClassName(index)} key={index}>
          {str.split(': ', 2).map((ele, i) => (
            <span key={i}>{ele}</span>
          ))}
        </ListItem>
      ));
    }
    return null;
  };

  const getOpeningDaysBlockText = () => {
    const weekDay = new Date().getDay();
    const weekDayText = openingHoursObj?.weekday_text[
      weekDay === 0 ? 6 : weekDay - 1
    ].split(': ', 2);
    if (
      openingHoursObj?.open_now ||
      (!openingHoursObj?.open_now && weekDayText[1] !== 'Closed')
    ) {
      return `${weekDayText[1]} (${weekDayText[0].slice(0, 3)})`;
    }
    return weekDayText[0];
  };

  // Fallback image in case Places API returns no image or an invalid image
  const fallBackUrl = '/images/DefaultMapPopupBanner.png';

  const [imgUrl, setImgUrl] = useState(photo || fallBackUrl);

  useEffect(() => {
    if (photo) {
      const img = new Image();
      img.src = photo;

      img.onload = () => {
        if (img.height === 100 && img.width === 100) {
          setImgUrl(fallBackUrl);
          if (placeId) {
            handleImageError();
          }
        } else {
          setImgUrl(photo);
        }
      };

      img.onerror = () => {
        setImgUrl(fallBackUrl);
        if (placeId) {
          handleImageError();
        }
      };
    }
  }, [photo, placeId, handleImageError]);

  // Use the provided `photos` array or fallback image if no photos are available
  const carouselImages = photos && photos.length > 0 ? photos : [imgUrl];

  const {
    getPlaceDetails,
    place,
    setPlace,
    getCachedPlacesItem,
    extractAddressComponents,
    createMapPinForPlace,
  } = useMapUtils();

  const refreshPlaceDetails = async () => {
    setCardLoading(true);
    window?.heap?.track('Map-card-refreshed');

    try {
      // get details from sessionStorage, if it doesn't exist,
      let details = getCachedPlacesItem(placeId) || null;

      if (details === null) {
        details = await getPlaceDetails(placeId, type, true);
        window?.heap?.track('Map-card-new-request');
      } else {
        window?.heap?.track('Map-card-cache-used');
      }

      setPlace({
        ...place,
        maps: details?.maps,
        website: details?.website,
        photo: details?.photo,
        photos: details?.photos,
        rating: details?.rating,
        lat: details?.lat,
        long: details?.long,
        ratingCount: details?.ratingCount,
        openingHoursObj: details?.openingHoursObj,
        addressComponents: details?.addressComponents,
        formattedAddress: details?.formattedAddress,
        types: details?.types,
        formattedPhoneNumber: details?.formattedPhoneNumber,
      });
    } catch (err) {
      // Error - probably because it's not a valid placeId
    }
    setCardLoading(false);
  };

  useEffect(() => {
    if (placeId && placeId !== currPlaceId) {
      setCurrPlaceId(placeId);
      refreshPlaceDetails();
    }
  }, [placeId]);

  const handleSnackBarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const handleAddActivity = async (
    activityTitle,
    locationId,
    sectionId,
    sectionName,
    locationName,
    autoAdd = false
  ) => {
    setLoader(true);
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    setDestinationDelete(false);
    setActivityDelete(false);
    const result = await initAction({
      newLocationId: locationId,
      newSectionId: sectionId,
    });
    playSound(SOUNDS.inOutClick);

    // action already done, no need to repeat activity
    if (result === 'NO-ACTION') {
      setLoader(false);
      return;
    }

    phTrackEvent({
      event: autoAdd
        ? EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_MAP_CARD_AUTO_ADD
        : EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_MAP_CARD_MANUAL_ADD,
    });
    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
        placeId,
        categoryId,
      },
      'ACTIVITY',
      {
        openingHoursObj,
        addressComponents,
        formattedAddress,
        formattedPhoneNumber,
        maps,
        website,
      }
    );
    phTrackEvent({
      event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_MAP_CARD_AUTO_ADD_ACTIVITY}${title}`,
    });
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    const lastUpdatedSectionDetails = JSON.parse(
      window?.sessionStorage?.getItem('lastUpdatedSectionDetails') || '{}'
    );
    const lastUpdatedSectionId =
      (autoAdd &&
        lastUpdatedSectionDetails &&
        lastUpdatedSectionDetails[locationId]) ||
      sectionId;
    let addedActivity = null;
    if (sectionId === null) {
      addedActivity = await dispatch(
        createNewActivityInLocation({
          locationId,
          activityTitle: title,
          mapPin: mapPin?.id,
          ...address,
        })
      );
      setToastMessage(`Added to ${locationName}`);
      setShowSnackBar(true);
    } else {
      addedActivity = await dispatch(
        createActivity({
          variables: {
            title,
            mapPin: mapPin?.id,
            thingsToDoId: lastUpdatedSectionId,
            index: thingsToDo[lastUpdatedSectionId]?.todos?.length,
            ...address,
          },
          sectionId: lastUpdatedSectionId,
          index: thingsToDo[lastUpdatedSectionId]?.todos?.length,
        })
      );
      // updated the last updated section under destination
      updateSessionStorageForLastEditedSection(
        locationId,
        lastUpdatedSectionId
      );
      const element = document.getElementById(`activity-blade-${mapPin?.id}`);
      if (element) {
        const activityId = addedActivity?.payload?.createTodo?.id;
        if (activityId) {
          dispatch(
            actions.View.setHighlightedToDo({
              todo: activityId,
            })
          );
        }
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      if (sectionName) {
        setToastMessage(`Added to ${locationName} > ${sectionName}`);
      } else {
        setToastMessage(`Added to ${locationName}`);
      }
      setShowSnackBar(true);
    }
    trackEvents(Events.LocationAddedFromMap);
    await postAction({
      locationId,
      sectionId,
    });
    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({
        delay: 1000,
      });
    } else {
      getTour()
        .onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO, handleClose)
        .openTour({
          delay: 1000,
        });
    }
  };

  const handleUndo = async () => {
    setShowSnackBar(false);
    phTrackEvent({
      event: EVENTS.PLAN_MAP_CARD.ACTIVITY_DELETE_UNDO,
    });
    if (activeCardDetails?.locationId) {
      dispatch(
        LocationActions.addLocationSectionTemp({
          locationId: activeCardDetails?.locationId,
        })
      );
      if (destinationDeletetimeoutRef.current) {
        clearTimeout(destinationDeletetimeoutRef.current);
        destinationDeletetimeoutRef.current = null;
      }
    } else {
      await handleAddActivity(
        lastAddedActivity?.activityTitle,
        lastAddedActivity?.locationId,
        lastAddedActivity?.sectionId,
        lastAddedActivity?.sectionName,
        lastAddedActivity?.locationName,
        false
      );
    }
  };

  // Check if a pin that's not a trip map pin, is added to the trip
  const checkIfAddedToPlan = () =>
    pins?.filter(
      (pin) => !tripMapPins?.includes(pin?.id) && pin?.placeId === placeId
    )?.length > 0 || false;

  return (
    <>
      <Card
        sx={{ width: 280, height: 400, position: 'relative', zIndex: 0 }}
        onMouseEnter={() => {
          setShowIconButton(true);
          setShowCarouselArrows(true);
        }}
        onMouseLeave={() => {
          setShowIconButton(false);
          setShowCarouselArrows(false);
        }}>
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            zIndex: 0,
          }}>
          {showIconButton && (
            <IconButton
              className={classes.close}
              disableFocusRipple
              disableRipple
              onClick={handleClose}
              style={{ cursor: 'pointer', zIndex: 1 }}>
              <Close sx={{ fontSize: '14px', color: '#000000' }} />
            </IconButton>
          )}

          <Box
            sx={{
              position: 'relative',
              height: '100%',
              overflow: 'hidden',
              zIndex: 0,
            }}>
            <Box
              sx={{
                transition: 'height 0.3s ease-in-out',
                height: isExpanded ? '60%' : '100%',
                overflow: 'hidden',
                zIndex: 1,
              }}>
              <Carousel
                showArrows={showCarouselArrows}
                images={carouselImages}
                isRecomendationCard={Boolean(true)}
                isLargerHeight={Boolean(true)}
              />
            </Box>

            <CardContent
              sx={{
                position: 'absolute',
                bottom: '-10px',
                width: '100%',
                height: 'auto',
                backgroundColor: 'rgba(255, 255, 255)',
                transition: 'height 0.3s ease-in-out',
                overflow: 'hidden',
                padding: '10px',
                zIndex: 2,
              }}>
              <div className={classes.cardContent}>
                <div className={classes.headerContainer}>
                  <div className={classes.flexLeft}>
                    <div className={classes.title}>
                      <TextTooltipElement title={title}>
                        <Typography
                          noWrap
                          variant="h4"
                          sx={{ fontWeight: 'bold' }}>
                          {title}
                        </Typography>
                      </TextTooltipElement>
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        marginLeft: 'auto',
                      }}>
                      <PinToMapIcon
                        key={title}
                        mapPinDetails={{
                          id: pinId,
                          lat,
                          long,
                          pinData: {
                            title,
                            photo,
                            rating,
                            description,
                            website,
                            maps,
                            ratingCount,
                            types,
                          },
                          categoryId,
                          pinColor,
                          type,
                          placeId,
                        }}
                        pinned={pinned}
                        pins={pins}
                        tripId={tripId}
                        isAdded={checkIfAddedToPlan()}
                        isQuickAddActivityEnabled={Boolean(true)}
                        isCommandBarEnabled={isCommandBarEnabled}
                        handleDeleteMapPin={handleDeleteMapPin}
                        locationId={activeCardDetails?.locationId}
                        setDeleteModalOpen={setDeleteModalOpen}
                      />
                    </div>
                  </div>
                </div>
                {cardLoading ? (
                  <div>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton variant="rounded" width="100%" height="80" />
                  </div>
                ) : (
                  <>
                    <div className={classes.ratingContainer}>
                      {placeType && (
                        <TextTooltipElement title={placeType}>
                          <span className={classes.placeType}>{placeType}</span>
                        </TextTooltipElement>
                      )}
                    </div>
                    <div className={classes.detailsContainer}>
                      {!isExpanded && formattedAddress && (
                        <div className={classes.detailsBlock}>
                          <LocationPinIconFilled
                            fill="#ED702E"
                            className={classes.detailsBlockIcon}
                          />
                          <TextTooltipElement title={formattedAddress}>
                            <Typography
                              className={classes.addressComponent}
                              style={{ WebkitLineClamp: 1, lineClamp: 1 }}>
                              {formattedAddress}
                            </Typography>
                          </TextTooltipElement>
                          {openingHoursObj || formattedPhoneNumber ? (
                            <Typography
                              onClick={handleToggle}
                              sx={{
                                color: '#000000',
                                cursor: 'pointer',
                                fontSize: '12px',
                                fontWeight: '500',
                                marginLeft: '8px',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}>
                              See more
                            </Typography>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <Collapse in={isExpanded}>
                      <div className={classes.detailsContainer}>
                        {formattedAddress && (
                          <div className={classes.detailsBlock}>
                            <LocationPinIconFilled
                              fill="#ED702E"
                              className={classes.detailsBlockIcon}
                            />
                            <TextTooltipElement title={formattedAddress}>
                              <Typography
                                className={classes.detailedAddressComponent}
                                style={{ WebkitLineClamp: 1, lineClamp: 1 }}>
                                {formattedAddress}
                              </Typography>
                            </TextTooltipElement>{' '}
                          </div>
                        )}
                        {openingHoursObj && (
                          <div
                            className={classes.detailsBlock}
                            style={{ display: 'flex', alignItems: 'center' }}
                            onMouseOver={handleDateHover}
                            onMouseLeave={() => setDatePopperOpen(false)}>
                            <ClockIcon
                              fill="#ED702E"
                              className={classes.detailsBlockIcon}
                            />
                            <Typography className={classes.description}>
                              {openingHoursObj?.open_now ? (
                                <span style={{ color: '#149F22' }}>
                                  OPEN NOW
                                </span>
                              ) : (
                                <span style={{ color: '#E5493D' }}>CLOSED</span>
                              )}{' '}
                              · {getOpeningDaysBlockText()}
                            </Typography>
                            <ArrowIcon />
                            <Popper
                              id={datePopperId}
                              placement="top-start"
                              open={datePopperOpen}
                              anchorEl={dateAnchor}
                              className={classes.datePopper}>
                              <List className={classes.dateList}>
                                {renderOpeningDays()}
                              </List>
                            </Popper>
                          </div>
                        )}
                        {formattedPhoneNumber && (
                          <div className={classes.detailsBlock}>
                            <CallIcon
                              fill="#ED702E"
                              className={classes.detailsBlockIcon}
                            />
                            <Typography className={classes.description}>
                              {formattedPhoneNumber}
                            </Typography>
                          </div>
                        )}
                      </div>
                      <Typography
                        onClick={handleToggle}
                        sx={{
                          color: '#000000',
                          cursor: 'pointer',
                          fontSize: '12px',
                          fontWeight: '500',
                          marginLeft: '8px',
                          width: 'fit-content',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}>
                        {isExpanded && 'See less'}
                      </Typography>
                    </Collapse>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}>
                      {maps && (
                        <Box
                          sx={{
                            '&:hover': {
                              backgroundColor: '#f4f4f4',
                            },
                            height: '30px',
                            width: '30px',
                            borderRadius: '4px',
                            padding: '3px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onClick={() => window.open(maps, '_blank')}>
                          <IconButton
                            sx={{
                              padding: 0,
                              margin: 0,
                            }}
                            disableFocusRipple
                            disableRipple>
                            <GoogleMapsIcon />
                          </IconButton>
                        </Box>
                      )}
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        mt="2px"
                        ml="-8px">
                        <StarRounded
                          sx={{
                            color: '#ED702E',
                            height: 20,
                            width: 20,
                          }}
                        />
                        <Typography
                          variant="h5"
                          color="text.secondary"
                          ml="2px"
                          mt="1px"
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          <b>{rating}</b>{' '}
                          {`(${
                            (ratingCount && formatNumber(ratingCount)) || 0
                          })`}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        id="add-to-trip-button-container"
                        sx={{ width: 'auto' }}>
                        <AddToTripButtonNew
                          tripId={tripId}
                          isAdded={checkIfAddedToPlan()}
                          placeDetails={{
                            title,
                            description,
                            photo,
                            rating,
                            website,
                            maps,
                            ratingCount,
                            long,
                            lat,
                            types,
                            placeId,
                            categoryId,
                          }}
                          additionalPlaceDetails={{
                            openingHoursObj,
                            addressComponents,
                            formattedAddress,
                            formattedPhoneNumber,
                            maps,
                            website,
                          }}
                          addressComponents={addressComponents}
                          hoverContentClass={classes.hoverContent}
                          activeCardDetails={activeCardDetails}
                          handleAddActivity={handleAddActivity}
                          setToastMessage={setToastMessage}
                          setShowSnackBar={setShowSnackBar}
                          setLastAddedActivity={setLastAddedActivity}
                          setDestinationDelete={setDestinationDelete}
                          setActivityDelete={setActivityDelete}
                          destinationDeletetimeoutRef={
                            destinationDeletetimeoutRef
                          }
                          isCommandBarEnabled={isCommandBarEnabled}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </CardContent>
          </Box>
        </Box>
      </Card>
      {portalContainer && (
        <Portal container={portalContainer}>
          <Snackbar
            className={classes.snackBar}
            open={showSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackBarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              maxWidth: '600px',
              width: '100%',
            }}>
            <Alert
              severity={
                (destinationDelete || activityDelete) && checkIfAddedToPlan()
                  ? 'error'
                  : checkIfAddedToPlan()
                  ? 'success'
                  : 'error'
              }
              variant="filled"
              iconMapping={{
                success: <CheckCircleOutline fontSize="inherit" />,
                error: <CancelOutlined fontSize="inherit" />,
              }}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '8px',
                paddingLeft: '8px',
                paddingTop: '1px',
                paddingBottom: '1px',
              }}
              action={
                (!checkIfAddedToPlan() ||
                  destinationDelete ||
                  activityDelete) && (
                  <Typography
                    onClick={handleUndo}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'white',
                      marginLeft: 4,
                      marginRight: 2,
                      paddingBottom: 0.5,
                    }}>
                    Undo
                  </Typography>
                )
              }>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flexGrow: 1,
                }}>
                {toastMessage}
              </Typography>
            </Alert>
          </Snackbar>
        </Portal>
      )}
      <DeleteModal
        executeFunc={handleDeleteMapPin}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Are you sure?"
        descriptionText="Deleting this item will delete all the information nested under it."
        confirmText="Yes, delete it!"
        cancelText="No, keep it."
      />
    </>
  );
}

export default MapPopupCardNew;
