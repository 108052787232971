import React, { useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Slider from 'react-slick';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImgWithFallback from '../../atoms/ImgWithFallback';

const useStyles = makeStyles(() => ({
  cardMedia: {
    height: (props) => (props.isLargerHeight ? '300px' : '200px'),
    objectFit: 'cover',
    borderRadius: 4,
    width: '100%',
  },
  carouselIndicator: {
    borderRadius: '50%',
    transition: '0.1s',
    backgroundColor: '#FFF',
    height: '4px',
    width: '4px',
    margin: '0px 3px',
    boxShadow: 'none',
    display: 'inline-block',
  },
  cityPageCarouselIndicator: {
    borderRadius: '50%',
    transition: '0.1s',
    backgroundColor: '#ED702E',
    height: '10px',
    width: '10px',
    margin: '0px 3px',
    boxShadow: 'none',
    display: 'inline-block',
  },
  controlArrowContainer: {
    zIndex: 2,
    top: 0,
    cursor: 'pointer',
    position: 'absolute',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 4px',
  },
  controlArrow: {
    height: 24,
    width: 24,
    backgroundColor: '#FFF',
    color: '#ED702E',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  indicatorList: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    transition: '0.2s',
    transitionDelay: '0.2s',
  },
  slidersContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '20px',
  },
  indicatorContainer: {
    display: 'flex',
    maxWidth: (props) => (props.isCityPage ? '100px' : '50px'),
    overflow: 'hidden',
    justifyContent: 'flex-start',
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFFB2',
    width: '60px',
    height: '32px',
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    padding: '8px 4px',
    borderRadius: '4px',
  },
}));

function CarouselArrow({ Icon, onClick, isNext, shouldDisplay }) {
  const classes = useStyles();
  return (
    <div
      className={classes.controlArrowContainer}
      style={{
        right: isNext ? 0 : 'auto',
        left: isNext ? 'auto' : 0,
        opacity: shouldDisplay ? 1 : 0,
        transition: 'all 0.1s ease-in-out',
      }}>
      <IconButton
        className={classes.controlArrow}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}>
        <Icon sx={{ height: 16, width: 16 }} />
      </IconButton>
    </div>
  );
}

function CustomCarousel({
  images,
  showArrows,
  isRecomendationCard = false,
  isCityPage = false,
  isLargerHeight = false,
  customClassName = '',
  onImageClick = () => {},
}) {
  const classes = useStyles({ isCityPage, isLargerHeight });
  const MAX_SLIDES = 5;
  const INDICATOR_WIDTH = 10;

  const [currSlide, setCurrSlide] = useState(0);
  const [offset, setOffset] = useState(0);
  const [ignoredImages, setIgnoredImages] = useState([]);

  const filteredImages = useMemo(
    () => images?.filter((i) => !ignoredImages?.includes(i)),
    [ignoredImages, images]
  );
  const totalSlides = filteredImages?.length;

  const handleCarouselChange = (prevIndex, newIndex) => {
    const index = newIndex % totalSlides;
    let newOffset = offset / INDICATOR_WIDTH;
    if (index <= 2) {
      newOffset = 0;
    } else if (index >= totalSlides - 3) {
      newOffset = totalSlides - MAX_SLIDES;
    } else {
      newOffset += currSlide < index ? 1 : -1;
    }

    setOffset(newOffset * INDICATOR_WIDTH);
    setCurrSlide(index);
  };

  const sliders = () => (
    <div className={classes.slidersContainer}>
      <div className={classes.indicatorContainer}>
        <div
          className={classes.indicatorList}
          style={{
            transform: `translateX(-${offset}px)`,
          }}>
          {[...Array(totalSlides).keys()]?.map((idx) => (
            <span
              key={idx}
              className={
                isCityPage
                  ? classes.cityPageCarouselIndicator
                  : classes.carouselIndicator
              }
              style={{
                opacity: idx === currSlide ? 1 : 0.4,
              }}
              value={idx}
            />
          ))}
        </div>
      </div>
    </div>
  );

  const statusIndicator = () => (
    <Box className={classes.statusContainer}>
      <Typography>{`${currSlide}/${totalSlides}`}</Typography>
    </Box>
  );

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
      }}>
      <Slider
        beforeChange={handleCarouselChange}
        nextArrow={
          <CarouselArrow
            Icon={(props) => (
              <ChevronRightRounded
                {...props}
                sx={{
                  color: '#000000',
                  fontSize: '14px',
                }}
              />
            )}
            shouldDisplay={showArrows}
            isNext
          />
        }
        prevArrow={
          <CarouselArrow
            Icon={(props) => (
              <ChevronLeftRounded
                {...props}
                sx={{ color: '#000000', fontSize: '14px' }}
              />
            )}
            shouldDisplay={showArrows}
          />
        }>
        {totalSlides > 0 ? (
          filteredImages?.map((image, idx) => (
            <div key={`im-${idx}`}>
              <ImgWithFallback
                onClick={() => {
                  onImageClick(image);
                }}
                src={isRecomendationCard ? image : image?.small}
                alt="hotel-img"
                className={`${classes.cardMedia} ${customClassName}`}
                onImageError={() => setIgnoredImages([...ignoredImages, image])}
              />
            </div>
          ))
        ) : (
          <img
            src="/images/placeholderImage.png"
            alt="pilot_placeholder_image"
            className={classes.cardMedia}
          />
        )}
      </Slider>
      {/* Transitions don't seem to work if sliders was defined as a "nested" component */}
      {totalSlides > 1 && sliders()}
      {isCityPage ? statusIndicator() : null}
    </div>
  );
}

export default CustomCarousel;
