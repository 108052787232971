import { makeStyles } from '@mui/styles';
import { OutlinedFlagRounded } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography, Fade } from '@mui/material';

const useStyles = makeStyles({
  flagContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
});

function ActivityButton({
  commonStyles,
  isClicked,
  handleAddActivity,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();

  return (
    <Grid item sx={{ mr: '-10px' }}>
      <Tooltip
        title={
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              Activity
            </Typography>
            {isKeyboardShortcutEnabled && (
              <Box sx={commonStyles.shortcutKey}>A</Box>
            )}
          </Box>
        }
        enterDelay={500}
        TransitionComponent={Fade}
        placement="top"
        arrow
        componentsProps={commonStyles.componentsProps}>
        <Box
          sx={isClicked ? commonStyles.clickedStyle : commonStyles.hoverStyle}
          onClick={handleAddActivity}>
          <OutlinedFlagRounded className={classes.flagContainer} />
        </Box>
      </Tooltip>
    </Grid>
  );
}
export default ActivityButton;
