import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  Popper,
  Typography,
  Tooltip,
  Paper,
  TextField,
  InputAdornment,
  Fade,
} from '@mui/material';
import { SearchIcon } from '@iconicicons/react';
import { Sparkles } from '../../../atoms/Icon';
import SuggestActivities from './suggestActivities';
import AskAi from './AskAI';
import SuggestIdeas from './SuggestIdeas';
// import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  headingContainer: {
    fontSize: '14px',
    color: '#000000',
    fontWeight: 500,
  },
  searchField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: 'inherit',
        borderWidth: '1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'inherit',
        borderWidth: '1px',
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
  searchIcon: {
    height: '30px',
    width: '30px',
    color: '#000000',
  },
  textHighlight: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    transition: 'background-color 0.3s',
  },
});

function AiButton({
  aiPopperRef,
  commonStyles,
  isClicked,
  handleButtonClick,
  tripId,
  activeLocationId,
  locations,
  aiAnchorEl,
  handleClick,
  activeSection,
  setActiveSection,
  isKeyboardShortcutEnabled,
  value,
  setValue,
}) {
  const classes = useStyles();
  const [suggestion, setSuggestion] = useState('');
  const [keyLabel, setKeyLabel] = useState('Ctrl');
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    // Detect if the platform is macOS
    if (navigator.userAgent.toLowerCase().includes('mac')) {
      setKeyLabel('⌘');
    }
  }, []);

  useEffect(() => {
    if (value === '') {
      setActiveSection('askAI');
      setIsHighlighted(false);
      setSuggestion('');
    } else if (value === 'Suggest Ideas ') {
      setSuggestion('Activities');
    }
  }, [value]);

  const handleBackspace = (e) => {
    const input = e.target;
    if (e.key === 'Backspace' && value === 'Suggest Ideas ') {
      if (!isHighlighted) {
        input.setSelectionRange(0, value.length - 1);
        setIsHighlighted(true);
        e.preventDefault();
      } else {
        setValue('');
        setActiveSection('askAI');
        setIsHighlighted(false);
      }
    }
    // Prevent the default behavior of arrow keys
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
    }
  };

  const open = Boolean(aiAnchorEl);

  return (
    <Grid item sx={{ ml: '-10px' }}>
      <Tooltip
        title={
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              AI
            </Typography>
            {isKeyboardShortcutEnabled && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Box sx={{ ...commonStyles.shortcutKey, marginLeft: '3px' }}>
                  {keyLabel}
                </Box>
                <Box
                  sx={{
                    marginLeft: '3px',
                    fontSize: '12px',
                    color: '#8A8A8A',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  +
                </Box>
                <Box sx={{ ...commonStyles.shortcutKey, marginLeft: '3px' }}>
                  K
                </Box>
              </Box>
            )}
          </Box>
        }
        enterDelay={500}
        TransitionComponent={Fade}
        placement="top"
        arrow
        componentsProps={commonStyles.componentsProps}>
        <Box
          sx={{
            ...(isClicked
              ? commonStyles.clickedStyle
              : commonStyles.hoverStyle),
            ...(aiAnchorEl !== null && {
              borderRadius: 2,
              backgroundColor: '#e5c5ff',
              '&:hover': {
                backgroundColor: '#e5c5ff',
                cursor: 'pointer',
                borderRadius: 2,
              },
            }),
          }}
          onClick={handleClick}
          ref={aiPopperRef}>
          <Sparkles
            style={{
              width: '42px',
              height: '42px',
              color: aiAnchorEl ? '#A12BFF' : '#4e4e4e',
            }}
          />
        </Box>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={aiAnchorEl}
        placement="top-end"
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [12, 20],
            },
          },
        ]}>
        {activeSection === 'suggestActivities' ? (
          <SuggestActivities
            tripId={tripId}
            activeLocationId={activeLocationId}
            locations={locations}
            handleButtonClick={handleButtonClick}
            setActiveSection={setActiveSection}
            setValues={setValue}
            setSuggestion={setSuggestion}
          />
        ) : (
          <Paper sx={{ padding: 2, width: '595px', borderRadius: '12px' }}>
            <Box
              className={classes.headerContainer}
              sx={{ justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Sparkles
                  style={{
                    width: '38px',
                    height: '38px',
                  }}
                />
                <Typography className={classes.headingContainer}>
                  Pilot Command
                </Typography>
              </Box>

              <Typography sx={{ color: 'gray', fontSize: '14px' }}>
                Esc
              </Typography>
            </Box>

            {activeSection === 'askAI' ? (
              <AskAi
                setActiveSection={setActiveSection}
                setSuggestion={setSuggestion}
                value={value}
                setValue={setValue}
              />
            ) : (
              <SuggestIdeas
                setActiveSection={setActiveSection}
                value={value}
                setValue={setValue}
                setSuggestion={setSuggestion}
              />
            )}

            <Box sx={{ position: 'relative' }}>
              <TextField
                placeholder="Suggest"
                sx={{
                  '&:hover .MuiOutlinedInput-root': {
                    borderColor: '#C3C3C3',
                  },
                  '& .MuiOutlinedInput-root.Mui-focused': {
                    borderColor: '#C3C3C3',
                  },
                }}
                autoFocus
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleBackspace}
                value={value}
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {activeSection === 'askAI' ? (
                        <SearchIcon className={classes.searchIcon} />
                      ) : (
                        <Sparkles
                          style={{
                            width: '34px',
                            height: '34px',
                            color: '#000000',
                            borderRadius: '8px',
                            backgroundColor: isHighlighted ? '#f4f4f4' : '',
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {suggestion && (
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    top: -4,
                    left: 153,
                    padding: '16px',
                    color: 'gray',
                    pointerEvents: 'none',
                    opacity: 0.5,
                  }}>
                  {suggestion}
                </Typography>
              )}
            </Box>
          </Paper>
        )}
      </Popper>
    </Grid>
  );
}
export default AiButton;
