import { makeStyles } from '@mui/styles';
import { HotelRounded } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography, Fade } from '@mui/material';

const useStyles = makeStyles({
  hotelContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
});

function HotelButton({
  commonStyles,
  isClicked,
  handleAddAccommodation,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();

  return (
    <Grid item>
      <Tooltip
        title={
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              Stays
            </Typography>
            {isKeyboardShortcutEnabled && (
              <Box sx={commonStyles.shortcutKey}>S</Box>
            )}
          </Box>
        }
        enterDelay={500}
        TransitionComponent={Fade}
        placement="top"
        arrow
        componentsProps={commonStyles.componentsProps}>
        <Box
          sx={isClicked ? commonStyles.clickedStyle : commonStyles.hoverStyle}
          onClick={handleAddAccommodation}>
          <HotelRounded className={classes.hotelContainer} />
        </Box>
      </Tooltip>
    </Grid>
  );
}

export default HotelButton;
