import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../redux/actions';
import {
  getSteps,
  tripNameToSessionEndEvent,
} from '../../../assets/onboarding/steps';
import { phTrackEvent } from '../../../analytics';

// A tour class that can be used to control the tour state
// It uses factory pattern to create a singleton instance of the tour, and to perform chainable operations on the tour
// For eg:
// const tour = Tour.getInstance();
// tour.openTour().setTourName('My Tour').setTourCurrentStep(1).setTourInProgress(true);
// tour.isActiveStep(1).goToNextStep().closeTour();

class Tour {
  constructor({
    tourInProgress = false,
    tourCurrentStep = 0,
    tourName = '',
    tourOpen = false,
    dispatch = useDispatch(),
    stepName = '',
    skippedStepCount = 0,
    variant = 'default',
  }) {
    this.tourInProgress = tourInProgress;
    this.tourCurrentStep = tourCurrentStep;
    this.tourName = tourName;
    this.tourOpen = tourOpen;
    this.dispatch = dispatch;
    this.steps = getSteps(tourName, {
      filterWithFeatureFlags: true,
      variant,
    });
    this.bool = false;
    this.stepName = stepName;
    this.chainable = true;
    this.skippedStepCount = skippedStepCount;
    this.variant = variant;
  }

  closeTour() {
    if (!this.chainable) return this;
    this.tourOpen = false;
    this.dispatch(actions.View.setTourOpen(false));
    return this;
  }

  openTour({ delay = 0 } = {}) {
    if (!this.chainable) return this;
    if (this.steps[this.tourCurrentStep]?.preOpen) {
      this.steps[this.tourCurrentStep]?.preOpen();
    }
    if (delay) {
      setTimeout(() => {
        this.tourOpen = true;
        this.dispatch(actions.View.setTourOpen(true));
      }, delay);
    } else {
      this.tourOpen = true;
      this.dispatch(actions.View.setTourOpen(true));
    }
    return this;
  }

  startTour(newTourName) {
    this.tourInProgress = true;
    this.tourOpen = true;
    this.tourName = newTourName;
    this.tourCurrentStep = 0;
    this.stepName = this.steps[0]?.stepName;
    this.dispatch(actions.View.setTourInProgress(true));
    this.dispatch(actions.View.setTourOpen(true));
    this.dispatch(actions.View.setTourName(newTourName));
    this.dispatch(actions.View.setTourCurrentStep(0));
    this.dispatch(actions.View.setTourCurrentStepName(this.steps[0]?.stepName));
    return this;
  }

  onActiveStep(step, callback) {
    if (!this.chainable) return this;
    const isStepActive =
      typeof step === 'string'
        ? this.stepName === step
        : step.includes(this.stepName);
    if (isStepActive && this.tourInProgress) {
      if (callback) {
        callback();
      }
      this.chainable = true;
      return this;
    }
    this.chainable = false;
    return this;
  }

  isActiveStep(step) {
    return this.stepName === step;
  }

  onActiveTour(tour, callback) {
    if (!this.chainable) return this;
    const isTourActive =
      typeof tour === 'string'
        ? this.tourName === tour
        : tour.includes(this.tourName);
    if (isTourActive && this.tourInProgress) {
      if (callback) {
        callback();
      }
      this.chainable = true;
      return this;
    }
    this.chainable = false;
    return this;
  }

  isTourActive(tour) {
    return typeof tour === 'string'
      ? this.tourName === tour
      : tour.includes(this.tourName);
  }

  goToNextStep() {
    if (!this.chainable) return this;
    this.tourCurrentStep += 1;
    this.stepName = this.steps[this.tourCurrentStep]?.stepName;
    if (this.steps[this.tourCurrentStep]?.onStepSet) {
      this.steps[this.tourCurrentStep]?.onStepSet();
    }
    if (this.steps[this.tourCurrentStep]?.preOpen && this.tourOpen) {
      this.steps[this.tourCurrentStep]?.preOpen();
    }
    this.stepName = this.steps[this.tourCurrentStep]?.stepName;
    this.dispatch(actions.View.setTourCurrentStep(this.tourCurrentStep));
    this.dispatch(actions.View.setTourCurrentStepName(this.stepName));
    return this;
  }

  skipNextStep() {
    if (!this.chainable) return this;
    this.tourCurrentStep += 2;
    this.stepName = this.steps[this.tourCurrentStep]?.stepName;
    this.dispatch(actions.View.setTourCurrentStep(this.tourCurrentStep));
    this.dispatch(actions.View.setTourCurrentStepName(this.stepName));
    return this;
  }

  complete() {
    if (!this.chainable && !this.tourInProgress) return this;
    const endEvent = tripNameToSessionEndEvent[this.tourName];
    if (endEvent) {
      phTrackEvent({
        event: endEvent,
      });
    }
    this.dispatch(actions.View.completeTour());
    return this;
  }

  getCount() {
    return {
      completed: this.tourCurrentStep - this.skippedStepCount + 1,
      total: this.steps.length - this.skippedStepCount,
    };
  }
}

export function useTour() {
  const {
    inProgress: tourInProgress,
    currentStep: tourCurrentStep,
    tourName,
    isOpen: tourOpen,
    currentStepName: tourStepName,
    skippedStepCount,
  } = useSelector((state) => state.View.tour);

  const dispatch = useDispatch();

  const getTour = () => {
    return new Tour({
      tourInProgress,
      tourCurrentStep,
      stepName: tourStepName,
      tourName,
      tourOpen,
      dispatch,
      skippedStepCount,
    });
  };

  return {
    tourInProgress,
    tourCurrentStep,
    tourName,
    tourStepName,
    getTour,
    tourOpen,
  };
}

export default useTour;
