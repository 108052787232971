import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import {
  TextField,
  InputAdornment,
  Popper,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { Search, Cancel } from '@mui/icons-material';
import classList from '../classList';
import { useMapUtils } from '../organisms/MapUtils';
import { PIN_ICONS, getCategoryIdFromTypes } from '../../utils';
import { useSounds, SOUNDS } from '../../sounds';
import { EVENTS, phTrackEvent } from '../../analytics';
import { SkeletonPlacesSearchBar } from './SearchBar';
import { useTour } from './Tour/useTour';
import { stepName } from '../../assets/onboarding/steps';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    padding: '0.5%',
    backgroundColor: '#FFF',
    borderRadius: 4,
    '&:focus-within': {
      borderColor: '',
    },
    width: '100%',
  },
  searchIcon: {
    color: '#A7A7A7',
  },
  clearIcon: {
    color: '#A7A7A7',
    width: '14px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    fontSize: 14,
    width: 'inherit',
    lineHeight: '140%',
    padding: '0px 8px',
    '&::placeholder': {
      color: '#A7A7A7',
    },
  },
  container: {
    width: '78.4%',
    [theme.breakpoints.down('md')]: {
      minWidth: 320,
      maxWidth: 400,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
    },
  },
  popper: {
    position: 'relative',
    zIndex: 1101,
    width: '100%',
  },
  list: {
    padding: '5px 10px',
    overflowY: 'auto',
    backgroundColor: '#FFF',
  },
  listItem: {
    display: 'flex',
    padding: '2%',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #DEDDDD',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  categoryIconContainer: {
    minHeight: 24,
    minWidth: 24,
    backgroundColor: '#ED702E',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
}));

export default function AutoCompleteSearchBar({
  setPlace,
  handleSelected,
  openMapPopup,
  setOpenMapPopup,
}) {
  const { viewport } = useMapUtils();
  const { playSound } = useSounds();
  const {
    suggestions: { status, data = [] },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      locationBias:
        window.google?.maps &&
        new window.google.maps.Circle({
          center: { lat: viewport.latitude, lng: viewport.longitude },
          radius: 30000,
        }),
    },
    cacheKey: 'default',
    debounce: 800,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [closeFlag, setCloseFlag] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('Search for places');
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleMouseLeave = () => {
    setCloseFlag(false);
  };

  useEffect(() => {
    if (status !== 'OK' || data.length > 0) {
      setLoading(false);
    }
  }, [status, data]);

  const handleMouseEnter = () => {
    setCloseFlag(true);
  };

  const { getTour } = useTour();

  const handleOpenMapPopup = (suggestion) => {
    // Get latitude and longitude via utility functions
    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId: suggestion.place_id,
      // Specify the return data that you want (optional)
      fields: [
        'name',
        'url',
        'website',
        'photos',
        'rating',
        'user_ratings_total',
        'geometry',
        'address_components',
        'types',
        'formatted_address',
        'formatted_phone_number',
        'opening_hours',
      ],
    };
    getDetails(parameter)
      .then((details) => {
        setPlace({
          placeId: suggestion?.place_id,
          title: details?.name,
          maps: details?.url,
          website: details?.website,
          photo: details?.photos?.length > 0 && details.photos[0].getUrl(),
          photos:
            details?.photos?.slice(0, 5).map((photo) => photo.getUrl()) || [],
          rating: details?.rating || 0,
          lat: details?.geometry?.location?.lat(),
          long: details?.geometry?.location?.lng(),
          ratingCount: details?.user_ratings_total,
          description: suggestion?.description,
          type: suggestion.type,
          payload: suggestion.payload,
          isMapGenerated: true,
          types: details?.types,
          addressComponents: details?.address_components,
          formattedAddress: details?.formatted_address,
          formattedPhoneNumber: details?.formatted_phone_number,
          openingHoursObj: details?.opening_hours,
          categoryId: getCategoryIdFromTypes(details?.types || []) || null,
        });
        handleSelected({
          latitude: details?.geometry?.location?.lat(),
          longitude: details?.geometry?.location?.lng(),
          viewport: details?.geometry?.viewport,
        });
        return details;
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    if (openMapPopup) {
      handleOpenMapPopup(openMapPopup);
      setOpenMapPopup(null);
    }
  }, [openMapPopup]);

  const ref = useRef(null);
  const inputRef = useRef(null);
  const id = open ? 'search-places-popper' : undefined;
  const renderSkeleton = () => {
    // Dummy options to show loading
    const items = [1, 2, 3, 4, 5];
    return (
      <>
        {items.map((_, index) => (
          <SkeletonPlacesSearchBar
            key={index}
            isLast={index === items.length - 1}
          />
        ))}
      </>
    );
  };

  const renderSuggestions = () =>
    data?.map((suggestion) => {
      const {
        placeId,
        structured_formatting: {
          main_text: mainText,
          secondary_text: secondaryText,
        },
        matched_substrings: matchedSubstrings,
        types = [],
      } = suggestion;

      const start = matchedSubstrings[0]?.offset;
      const end = (matchedSubstrings[0]?.length || 0) + start;
      const matchedText = mainText.substring(start, end);

      const formattedMainText = mainText.replace(
        matchedText,
        `<b>${matchedText}</b>`
      );
      const categoryId = getCategoryIdFromTypes(types) || 'default';
      const CategoryIcon = PIN_ICONS[categoryId]?.icon;
      return (
        <ListItem
          key={placeId}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            getTour().onActiveStep(stepName.MAP_SEARCH_BAR).goToNextStep();

            playSound(SOUNDS.softPop);
            phTrackEvent({
              event: EVENTS.PLAN_MAP.SEARCH_SUBMIT,
            });
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setValue(suggestion.description, false);
            clearSuggestions();
            handleOpenMapPopup(suggestion);
            setInputValue('');
          }}
          className={classes.listItem}
          justify="start">
          <div className={classes.categoryIconContainer}>
            <CategoryIcon sx={{ height: 14, width: 14, fill: '#FFF' }} />
          </div>
          <Stack>
            <Typography
              className={classes.listItemText}
              dangerouslySetInnerHTML={{
                __html: formattedMainText,
              }}
            />
            <Typography
              className={classes.listItemText}
              sx={{ color: '#666666', fontSize: '14px' }}>
              {secondaryText}
            </Typography>
          </Stack>
        </ListItem>
      );
    });

  const handleSearchBarClickInTour = () => {
    getTour()
      .onActiveStep(stepName.MAP_SEARCH_BAR, () => {
        inputRef?.current?.focus();
      })
      .goToNextStep()
      .closeTour();
  };
  return (
    <div
      onClick={handleSearchBarClickInTour}
      id="map-search-bar"
      className={`${classes.container} ${classList.mapSearchBar}`}>
      <TextField
        ref={ref}
        aria-describedby={id}
        value={inputValue}
        placeholder={placeHolder}
        className={classes.searchBar}
        variant="standard"
        onChange={(e) => {
          setLoading(e.target.value.length > 0);
          setValue(e.target.value);
          setInputValue(e.target.value);
        }}
        InputProps={{
          className: classes.input,
          startAdornment: (
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: inputValue !== '' && (
            <InputAdornment position="end">
              <Cancel
                className={classes.clearIcon}
                onClick={() => {
                  clearSuggestions();
                  setInputValue('');
                }}
              />
            </InputAdornment>
          ),
          disableUnderline: true,
          id: 'map-search-bar-input',
        }}
        inputProps={{
          ref: inputRef,
          onFocus: () => {
            setAnchorEl(ref.current);
            setPlaceHolder('');
          },
          onBlur: () => {
            if (!closeFlag) {
              setAnchorEl(null);
            }
            setPlaceHolder('Search for places');
          },
        }}
      />
      <div style={{ width: '100%', position: 'relative' }}>
        {loading || status === 'OK' ? (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            className={classes.popper}
            disablePortal>
            <List className={classes.list}>
              {loading
                ? renderSkeleton()
                : status === 'OK'
                ? renderSuggestions()
                : null}
            </List>
          </Popper>
        ) : null}
      </div>
    </div>
  );
}
