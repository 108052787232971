import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Paper,
  Box,
  Grid,
  Tooltip,
  Popper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Fade,
} from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { GlobeIcon } from '@iconicicons/react';
import actions from '../../../redux/actions';

const useStyles = makeStyles({
  locationStyle: {
    marginLeft: '8px',
    marginRight: '4px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    cursor: 'pointer',
  },
  listItemContainer: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      color: '#000000',
    },
    maxWidth: '200px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  sectionListItemContainer: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#dbdbdb',
      color: '#000000',
    },
  },
  listItemText: {
    '&:hover': {
      color: '#000000',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  globeIconContainer: {
    height: 30,
    width: 30,
    color: '#4e4e4e',
  },
  arrowDropDown: {
    transform: 'rotate(90deg)',
    height: 22,
    width: 22,
  },
});

function DestinationButton({
  activeLocationId,
  anchorEl,
  setAnchorEl,
  locationIds,
  locations,
  thingsToDo,
  commonStyles,
  handleClickAway,
  isGlobeClicked,
  handleAddDestination,
  isArrowClicked,
  handleArrowClick,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [locationPopperOpen, setLocationPopperOpen] = useState(false);
  const [locationAnchor, setLocationAnchor] = useState(null);

  // remove the highlighted section and heading
  const removeHighlightedSectionAndHeading = () => {
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
  };

  const handleLocationClick = (locationId) => {
    // set the section also if exists
    const activeSectionId =
      (locations[locationId]?.thingsToDo?.length > 0 &&
        locations[locationId]?.thingsToDo[0]) ||
      null;

    dispatch(
      actions.View.setCommandBar({
        activeLocationId: locationId,
        activeSectionId,
      })
    );

    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );

    setAnchorEl(null);
  };

  const handleSectionClick = (locationId, sectionId) => {
    dispatch(
      actions.View.setCommandBar({
        activeLocationId: locationId,
        activeSectionId: sectionId,
      })
    );
    removeHighlightedSectionAndHeading();
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    handleArrowClick();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setLocationPopperOpen(anchorEl ? null : event.currentTarget);
  };

  const renderLocations = () =>
    locationIds &&
    locationIds.map((locationId, index) => {
      const location = locations[locationId];
      if (!location) return null;
      const { id, name } = location;
      const handleLocationHover = (e) => {
        const element = document.getElementById(
          `scrollable-container-${index}`
        );
        if (element) {
          dispatch(
            actions.View.setHighlightedSection({
              section: index,
            })
          );
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        if (locationPopperOpen === id) return;
        setLocationPopperOpen(id);
        setLocationAnchor(e.currentTarget);
      };
      const handleSectionHover = (e, highLightedsectionId) => {
        e.stopPropagation();
        dispatch(
          actions.View.setHighlightedSection({
            section: index,
          })
        );
        const element = document.getElementById(
          `todo-header-${highLightedsectionId}`
        );
        if (element) {
          dispatch(
            actions.View.setHighlightedHeading({
              section: highLightedsectionId,
            })
          );
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      };
      const sections =
        location?.thingsToDo?.map((thingsToDoId) => thingsToDo[thingsToDoId]) ||
        [];
      return (
        <ListItem
          key={id}
          onClick={() => handleLocationClick(id)}
          onMouseOver={handleLocationHover}
          onMouseLeave={() => {
            if (sections?.length === 0) {
              setLocationPopperOpen(null);
            }
            dispatch(
              actions.View.setHighlightedSection({
                section: null,
              })
            );
          }}
          className={classes.listItemContainer}
          alignItems="center">
          <ListItemIcon
            style={{
              minWidth: 0,
              marginRight: 4,
            }}>
            <GlobeIcon className={classes.globeIconContainer} />
          </ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {sections.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ArrowRightRoundedIcon style={{ color: '#000000' }} />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={locationPopperOpen === id}
                anchorEl={locationAnchor}
                placement="right-end"
                style={{ zIndex: 1123 }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 4],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <Paper>
                    <List
                      style={{
                        maxWidth: 220,
                        maxHeight: 400,
                        overflowY: 'auto',
                      }}
                      onMouseLeave={() => {
                        setLocationPopperOpen(null);
                      }}>
                      {sections?.map((section) => {
                        return (
                          <ListItem
                            key={section?.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSectionClick(id, section?.id);
                            }}
                            onMouseOver={(e) => {
                              handleSectionHover(e, section?.id);
                            }}
                            onMouseLeave={() => {
                              removeHighlightedSectionAndHeading();
                            }}
                            className={classes.sectionListItemContainer}
                            justify="center"
                            style={{ padding: '2px 12px', height: '40px' }}>
                            <ListItemText
                              primary={
                                section.name === ''
                                  ? 'Unnamed Section'
                                  : section?.name
                              }
                              primaryTypographyProps={{ fontSize: '14px' }}
                              className={classes.listItemText}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    });

  return (
    <Grid item>
      <Box
        id="destination-button-container"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Tooltip
          title={
            <Box display="flex" alignItems="center">
              <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                Destination
              </Typography>
              {isKeyboardShortcutEnabled && (
                <Box sx={commonStyles.shortcutKey}>D</Box>
              )}
            </Box>
          }
          enterDelay={500}
          TransitionComponent={Fade}
          placement="top"
          arrow
          componentsProps={commonStyles.componentsProps}>
          <Box
            sx={
              isGlobeClicked
                ? commonStyles.clickedStyle
                : commonStyles.hoverStyle
            }
            onClick={handleAddDestination}>
            <GlobeIcon
              id="destination-button-globe"
              className={classes.globeIconContainer}
            />
          </Box>
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '125px',
            ...(isArrowClicked
              ? commonStyles.arrowClickedStyle
              : commonStyles.clickableTextContainer),
          }}
          onClick={handleClick}>
          <Typography className={classes.locationStyle}>
            {locations[activeLocationId]?.name || 'New Destination'}
          </Typography>
          <Tooltip
            title={
              <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                Select destination
              </Typography>
            }
            enterDelay={500}
            TransitionComponent={Fade}
            placement="top"
            arrow
            componentsProps={commonStyles.componentsProps}>
            <Box sx={commonStyles.arrowStyle}>
              <ArrowRightRoundedIcon className={classes.arrowDropDown} />
            </Box>
          </Tooltip>
        </Box>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          style={{ zIndex: 1123 }}
          placement="top-end"
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper>
              <List style={{ maxHeight: 400, overflowY: 'auto' }}>
                {renderLocations()}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Grid>
  );
}

export default DestinationButton;
