import { airlines } from '@pilotplans/aviation-data';
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
} from 'date-fns';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import {
  CameraEnhanceRounded,
  RestaurantRounded,
  LocalBarRounded,
  StarRounded,
  DirectionsRunRounded,
  LocationOnRounded,
  HomeRounded,
  LocalMallRounded,
  LocalAirportRounded,
  DirectionsBusRounded,
  DirectionsTransitRounded,
  DirectionsCarRounded,
  DirectionsWalkRounded,
  DirectionsBikeRounded,
  CameraEnhanceOutlined,
  StarOutlineRounded,
  DirectionsRunOutlined,
  LocalBarOutlined,
  RestaurantOutlined,
  LocalMallOutlined,
  LocalAirportOutlined,
  DirectionsBusOutlined,
  HomeOutlined,
  LocationOnOutlined,
} from '@mui/icons-material';
import {
  DocFile,
  ImageFile,
  OtherFile,
  PdfFile,
  SpreadsheetFile,
} from './components/atoms/Icon';

import { storage } from './firebase/FirebaseIndex';
import actions from './redux/actions';
import { deleteFile } from './redux/slices/Files';
import config from './components/config';
import recommendationCategories from './assets/recommendationCategories.json';
import getCfConnector from './components/cfConnector';

export const ONE_DAY_MS = 24 * 60 * 60 * 1000;
export const getDaysDiff = (date1, date2) => {
  // date1 is prior to date2
  if (!(date1 && date2)) return null;
  if (date2 <= date1 || !date1 || !date2) return null;

  let diff = (date1.getTime() - date2.getTime()) / ONE_DAY_MS;
  diff = Math.floor(Math.abs(diff)) + 1;

  return diff > 0
    ? diff > 1
      ? `${diff.toString()} days`
      : `${diff.toString()} day`
    : null;
};

// converts 2445 reviews to 2.4k for better readability
export function formatRatingCount(ratingCount) {
  if (ratingCount < 1000) {
    return ratingCount.toString();
  }
  const countInK = ratingCount / 1000;
  const formattedCount = countInK.toFixed(1);
  return `${formattedCount}k`;
}

export const convertSecondsToFormatedDuration = (timeInSeconds) => {
  const days = Math.floor(timeInSeconds / (24 * 3600));

  let remainingSeconds = timeInSeconds % (24 * 3600);
  const hours = Math.floor(remainingSeconds / 3600);

  remainingSeconds %= 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  return `${days ? `${days}d ` : ''}${hours ? `${hours}hr ` : ''}${
    minutes ? `${minutes}min` : ''
  }`;
};

export const convertMetresToKms = (distanceInMetres = 0) => {
  const distanceInKms = Math.floor(distanceInMetres / 1000);
  return `${
    distanceInKms
      ? `${distanceInKms}km`
      : `${(distanceInMetres / 1000).toFixed(1)}km`
  }`;
};

const generateRandomId = (length = 20) => {
  let result = '';
  const characters =
    '-_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatDate = (date, dateFormat = 'iii, MMM d') => {
  if (!date) return '';

  const unformattedDate = new Date(date);
  return format(
    new Date(
      unformattedDate.valueOf() +
        unformattedDate.getTimezoneOffset() * 60 * 1000
    ),
    dateFormat
  );
};

export const formatTime = (time, timeFormat = 'h:mmaa') => {
  if (!time) return '';

  const unformattedTime = new Date(`${new Date().toDateString()} ${time}`);
  return format(unformattedTime, timeFormat);
};

export const formatTimeAMPM = (time) => {
  if (!time) return '';

  const hourEnd = time.indexOf(':');
  const tmpHour = Number(time.substr(0, hourEnd));
  const hour = tmpHour % 12 || 12;
  const ampm = tmpHour < 12 || tmpHour === 24 ? ' AM' : ' PM';
  const formattedTime = hour + time.substr(hourEnd, 3) + ampm;

  return formattedTime;
};

export const removeTimezoneOffset = (date) => {
  if (!date) return null;
  const unparsedDate = new Date(date);
  return new Date(
    unparsedDate.getTime() - unparsedDate.getTimezoneOffset() * 60000
  );
};

export const parseISODate = (date, version) => {
  if (!date) return null;
  const unparsedDate = new Date(date);
  if (unparsedDate.toISOString() === date && version !== null) {
    return new Date(
      unparsedDate.getTime() + unparsedDate.getTimezoneOffset() * 60000
    );
  }
  return unparsedDate;
};

export const getToday = () =>
  Date.parse(
    `${
      removeTimezoneOffset(new Date()).toISOString().split('T')[0]
    }T00:00:00.000Z`
  );

// Validate Image size
const validateImgSize = (file, maxSize) => {
  if (!file) return false;
  let fsize = file.size;
  fsize = Math.round(fsize / 1024);

  // The size of the file.
  if (fsize >= maxSize * 1024) {
    return false;
  }
  return true;
};

const handleFileUpload = async (file, path, fileName, maxSize = 2) => {
  // Upload image to storage
  if (!validateImgSize(file, maxSize)) return 'Image Size too Big!!';
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `${path}${fileName}`);
    const task = uploadBytesResumable(storageRef, file);

    // eslint-disable-next-line no-promise-executor-return
    return task.on(
      'state_changed',
      () => {
        // Here the status of the task can be checked. In the future,
        // if a progress bar is added, the status of the bar will be
        // served from here.
      },
      (error) => {
        reject(new Error(error));
      },
      () => {
        getDownloadURL(task.snapshot.ref)
          .then((downloadURL) => {
            return resolve({
              success: true,
              url: downloadURL,
            });
          })
          .catch((error) => {
            reject(new Error(error));
          });
      }
    );
  });
};

export const uploadNewFile = async (
  fileObj,
  userId,
  attachedToID,
  fileName,
  type,
  createFile,
  updateFile,
  attachedToType
) => {
  // FileObj is either an event compatible with handleFileUpload function, or a url to be attached
  const extension = fileObj.name.split('.').pop();
  return new Promise((resolve, reject) => {
    if (type === 'Upload') {
      createFile({
        variables: {
          name: fileName,
          size: fileObj.size,
          createdDate: new Date(),
          createdBy: userId,
          type: 'Upload',
          url: '',
          attachedToType,
          attachedToID,
        },
      })
        .then(
          async ({
            payload: {
              createFile: { id: fileObjId },
            },
          }) => {
            const uploadedFile = await handleFileUpload(
              fileObj,
              `/${attachedToID}/files/`,
              `${fileObjId}.${extension}`,
              4
            );
            if (!uploadedFile.success) reject(fileName);
            updateFile({
              variables: {
                id: fileObjId,
                url: uploadedFile.url,
                storagePath: `${attachedToID}/files/${fileObjId}.${extension}`,
              },
            })
              .then(() => resolve(fileObjId))
              .catch((err) => reject(err));
          }
        )
        .catch((err) => reject(err));
    } else {
      createFile({
        variables: {
          name: fileName,
          url: fileObj,
          size: 0,
          createdDate: new Date(),
          createdBy: userId,
          type: 'Link',
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    }
  });
};

export const handleProfileCoverImageUpload = (e, userId) => {
  return handleFileUpload(
    e.target.files[0],
    `/${userId}/`,
    'profileCoverImage'
  );
};

export const handleTripCoverImage = (e, tripId) => {
  return handleFileUpload(
    e.target.files[0],
    `/${tripId}/`,
    'tripCoverImage',
    10
  );
};

export const handleProfileImageUpload = (e, userId) => {
  return handleFileUpload(e.target.files[0], `/${userId}/`, 'profileImage');
};

export const handleWishlistImageUpload = (image, userId) => {
  return handleFileUpload(image, `/${userId}/wishlist/`, generateRandomId());
};

export const handleOldPhotoDelete = (oldUrl) => {
  const imgRef = ref(storage, oldUrl);

  // Delete the file using the delete() method
  deleteObject(imgRef)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const getTextWidth = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

export const getFileIcon = (extension) => {
  switch (extension) {
    case 'pdf':
      return PdfFile;
    case 'doc':
    case 'docx':
      return DocFile;
    case 'xls':
    case 'xlsx':
    case 'csv':
      return SpreadsheetFile;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
      return ImageFile;
    default:
      return OtherFile;
  }
};

export const handleFileDelete = (
  file,
  tripId,
  dispatch,
  Transportation,
  Files
) => {
  const attachedRelations = Files.fileRelations[tripId].filter(
    (relation) => relation.fileId === file.id
  );
  dispatch(
    deleteFile({
      variables: {
        id: file.id,
        fileRelation: attachedRelations,
      },
      tripId,
    })
  );
  attachedRelations.forEach((relation) => {
    switch (relation.attachedToType) {
      case 'Trip':
        dispatch(
          actions.Trips.deleteTripFile({
            tripId: relation.attachedToId,
            fileId: file.id,
          })
        );
        break;
      case 'Activity':
        dispatch(
          actions.Activity.deleteActivityFile({
            activityId: relation.attachedToId,
            fileId: file.id,
          })
        );
        break;
      case 'Accommodation':
        dispatch(
          actions.Accommodation.deleteAccommodationFile({
            accommodationId: relation.attachedToId,
            fileId: file.id,
          })
        );
        break;
      case 'Transportation':
        // TODO: Update this when flights is normalized.
        // eslint-disable-next-line no-case-declarations
        const transportId = Object.values(Transportation.transports)?.find(
          (transport) =>
            transport?.details
              ?.map((detail) => detail.id)
              .includes(relation.attachedToId)
        )?.id;
        dispatch(
          actions.Transportation.deleteTransportationFile({
            transportationId: transportId,
            flightId: relation.attachedToId,
            fileId: file.id,
          })
        );
        break;
      default:
        break;
    }
  });
};

export const PIN_ICONS = {
  default: {
    id: 'default',
    icon: LocationOnRounded,
    outlinedIcon: LocationOnOutlined,
  },
  stay: {
    id: 'stay',
    icon: HomeRounded,
    outlinedIcon: HomeOutlined,
  },
  camera: {
    id: 'camera',
    icon: CameraEnhanceRounded,
    outlinedIcon: CameraEnhanceOutlined,
  },
  favourite: {
    id: 'favourite',
    icon: StarRounded,
    outlinedIcon: StarOutlineRounded,
  },
  activity: {
    id: 'activity',
    icon: DirectionsRunRounded,
    outlinedIcon: DirectionsRunOutlined,
  },
  drinks: {
    id: 'drinks',
    icon: LocalBarRounded,
    outlinedIcon: LocalBarOutlined,
  },
  food: {
    id: 'food',
    icon: RestaurantRounded,
    outlinedIcon: RestaurantOutlined,
  },
  shop: {
    id: 'shop',
    icon: LocalMallRounded,
    outlinedIcon: LocalMallOutlined,
  },
  airplane: {
    id: 'airplane',
    icon: LocalAirportRounded,
    outlinedIcon: LocalAirportOutlined,
  },
  bus: {
    id: 'bus',
    icon: DirectionsBusRounded,
    outlinedIcon: DirectionsBusOutlined,
  },
};

export const COLORS = [
  '#E03D3E',
  '#ED702E',
  '#FDA8A1',
  '#FFA766',
  '#8AD6B0',
  '#4FA5AE',
  '#AE4FA4',
];

export const TRAVEL_MODES = {
  TRANSIT: {
    name: 'TRANSIT',
    icon: DirectionsTransitRounded,
    apiName: 'bus',
  },
  CAR: {
    name: 'CAR',
    icon: DirectionsCarRounded,
    apiName: 'car',
  },
  WALK: {
    name: 'WALK',
    icon: DirectionsWalkRounded,
    apiName: 'pedestrian',
  },
  BIKE: {
    name: 'BIKE',
    icon: DirectionsBikeRounded,
    apiName: 'bicycle',
  },
};

export const PLACES_CATEGORY_ICON_MAP = {
  airport: PIN_ICONS.airplane,
  lodging: PIN_ICONS.stay,
  amusement_park: PIN_ICONS.activity,
  aquarium: PIN_ICONS.activity,
  museum: PIN_ICONS.activity,
  zoo: PIN_ICONS.activity,
  art_gallery: PIN_ICONS.activity,
  bar: PIN_ICONS.drinks,
  cafe: PIN_ICONS.food,
  restaurant: PIN_ICONS.food,
  food: PIN_ICONS.food,
  bakery: PIN_ICONS.food,
  bus_station: PIN_ICONS.bus,
  train_station: PIN_ICONS.bus,
  shopping_mall: PIN_ICONS.shop,
  tourist_attraction: PIN_ICONS.camera,
  point_of_interest: PIN_ICONS.favourite,
  clothing_store: PIN_ICONS.shop,
  park: PIN_ICONS.activity,
  electronics_store: PIN_ICONS.shop,
  florist: PIN_ICONS.shop,
  book_store: PIN_ICONS.shop,
  jewelry_store: PIN_ICONS.shop,
  meal_delivery: PIN_ICONS.food,
  meal_takeaway: PIN_ICONS.food,
  campground: PIN_ICONS.activity,
  casino: PIN_ICONS.activity,
  store: PIN_ICONS.shop,
  supermarket: PIN_ICONS.shop,
};

export const PLACES_TYPE_MAP = {
  locality: 'City',
  country: 'Country',
  airport: 'Airport',
  lodging: 'Lodging',
  amusement_park: 'Amusement Park',
  aquarium: 'Aquarium',
  museum: 'Museum',
  zoo: 'Zoo',
  art_gallery: 'Art Gallery',
  bar: 'Drinks',
  cafe: 'Cafe',
  restaurant: 'Restaurant',
  bakery: 'Bakery',
  shopping_mall: 'Shopping mall',
  tourist_attraction: 'Tourist Attraction',
  park: 'Park',
  casino: 'Casino',
  store: 'Store',
  supermarket: 'Supermarket',
};

// return automatic selection of categoryId from a placesDetailsResponse
export const getCategoryIdFromTypes = (types = []) => {
  // Assigning the type based on the sorted list of importance of categories.
  const validTypes = Object.keys(PLACES_CATEGORY_ICON_MAP) || 0;
  for (let i = 0; i < validTypes.length; i += 1) {
    const type = validTypes[i];
    if (types?.includes(type)) return PLACES_CATEGORY_ICON_MAP[type].id;
  }
  return null;
};

export const getPlaceTypeFromTypes = (types = []) => {
  const validTypes = Object.keys(PLACES_TYPE_MAP) || 0;
  for (let i = 0; i < validTypes.length; i += 1) {
    const type = validTypes[i];
    if (types?.includes(type)) return PLACES_TYPE_MAP[type];
  }
  return null;
};

// straightforward converting slugs to titles
export function convertSlugToTitle(slug = '') {
  const words = slug?.split('-') || [];
  const titleWords = words?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return titleWords?.join(' ');
}

function generateNewDeviceId() {
  // Generate a random unique device ID (you can use a more complex method)
  const uniqueId =
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
  return uniqueId;
}

export function getDeviceId() {
  // Check if the device ID is already stored in Local Storage
  let deviceId = localStorage.getItem('deviceId');

  // If no device ID is found, generate a new one
  if (!deviceId) {
    deviceId = generateNewDeviceId();
    localStorage.setItem('deviceId', deviceId);
  }

  return deviceId;
}

export async function getImagesFromAPI(searchKeyword) {
  if (!searchKeyword) return [];

  try {
    const parsedResponse = await (
      await getCfConnector()
    )
      .post(config.imagesAPI, {
        keyword: searchKeyword,
      })
      .then(({ data }) => data);

    if (parsedResponse?.result?.errors) {
      return [];
    }
    return parsedResponse.result.response.results;
  } catch (error) {
    return [];
  }
}

export function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
export const getTopCategories = (categories = []) => {
  const mainCategories = Object.keys(recommendationCategories.order);
  const topCategories = categories.filter(
    (category) =>
      !mainCategories.includes(category) &&
      Boolean(recommendationCategories.ref[category])
  );

  return topCategories || [];
};

export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function cleanFloatString(input) {
  if (!input) return null;
  let cleanedFloatString = input.replace(/[^\d.]/g, '');

  // Remove multiple decimal points
  const parts = cleanedFloatString.split('.');
  if (parts.length > 2)
    cleanedFloatString = `${parts[0]}.${parts.slice(1).join('')}`;

  return cleanedFloatString;
}

export function getAirlineFromFlightNumber(flightNumber) {
  const matches = flightNumber.match(/^[A-Z-0-9]{2,3}/);

  if (matches && matches.length > 0) {
    let icaoCode = matches[0];

    // Checking if last letter is a number, treat as IATA. IATA can have numbers, ICAO cannot.
    if (icaoCode?.length === 3 && parseInt(icaoCode[2], 10)) {
      icaoCode = icaoCode.slice(0, 2);
    }
    let airline;
    // get ICAO code if IATA detected (AA 112 -> AAA)
    if (icaoCode?.length === 2) {
      airline = airlines.findWhere({ iata: icaoCode }) || null;
    } else {
      airline = airlines.findWhere({ icao: icaoCode }) || null;
    }

    if (!airline) {
      return {};
    }

    return {
      airlineIcaoCode: airline?.get('icao') || null,
      airline: airline?.get('name') || null,
    };
  }
  return {};
}
export function updateSessionStorageForLastEditedSection(
  locationId,
  sectionId
) {
  const lastUpdatedSectionDetails = JSON.parse(
    window?.sessionStorage?.getItem('lastUpdatedSectionDetails') || '{}'
  );
  lastUpdatedSectionDetails[locationId] = sectionId;
  window?.sessionStorage?.setItem(
    'lastUpdatedSectionDetails',
    JSON.stringify(lastUpdatedSectionDetails)
  );
}

export function truncateString(str, num) {
  if (str.length <= num) return str;
  return `${str.slice(0, num)}...`;
}

export function getFormattedDateRangeText(startDate, endDate) {
  // If the start date is the same as the end date, return a single date
  // if the start and end dates are in same month, MMM dd-dd (e.g. Jan 15-20)
  // if the start and end dates are in different months, MMM dd - dd, MMM (e.g. Jan 15 - 20, Jan)
  // if the start and end dates are in different years, MMM dd yy - MMM dd yy (e.g. Jan 15 2022 - Jan 20 2023)

  if (isSameDay(startDate, endDate)) {
    return format(startDate, 'MMM d');
  }

  if (isSameYear(startDate, endDate)) {
    if (isSameMonth(startDate, endDate)) {
      return `${format(startDate, 'MMM d')}-${format(endDate, 'd')}`;
    }
    return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;
  }

  return `${format(startDate, 'MMM d yyyy')} - ${format(
    endDate,
    'MMM d yyyy'
  )}`;
}

export function getCeiledDuration(startDate, endDate) {
  const diffInDays = differenceInDays(endDate, startDate);
  const diffInMonths = differenceInMonths(endDate, startDate);
  const diffInYears = differenceInYears(endDate, startDate);

  if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
  }
  if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''}`;
  }
  return `${diffInYears} year${diffInYears > 1 ? 's' : ''}`;
}

export function toRad(deg) {
  return deg * (Math.PI / 180);
}

export function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // approximate Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
}

export const geoDistanceCheck = ({ coordinates = [], threshold = 30 }) => {
  const distance = haversineDistance(
    coordinates[0]?.latitude,
    coordinates[0]?.longitude,
    coordinates[1]?.latitude,
    coordinates[1]?.longitude
  );
  return distance <= threshold;
};

export function getAirlineLogoFromFlightNumber(flightNumber) {
  const { airlineIcaoCode = null } = getAirlineFromFlightNumber(
    flightNumber || ''
  );
  return airlineIcaoCode
    ? `https://storage.googleapis.com/prod-airplane-logos/${airlineIcaoCode}.png`
    : null;
}

export function formatNumber(num) {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
}

export function isElementFullyVisible(el) {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  // Ensure the entire element is within the viewport
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= vHeight &&
    rect.right <= vWidth
  );
}

export function scrollIfNotVisibleBySelector(selector) {
  const el = document.querySelector(selector);
  if (!el) return;
  const isVisible = isElementFullyVisible(el);
  if (!isVisible) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
