import {
  DragIndicatorRounded,
  DeleteOutlineRounded,
  AddRounded,
} from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { GlobeIcon } from '@iconicicons/react';
import posthog from 'posthog-js';

import { EVENTS } from '../../analytics';
import { scrollIfNotVisibleBySelector } from '../../utils';
import flags from '../../featureFlags';

export const onboardingTourNames = {
  sampleTripFlowWeb: 'sampleTripFlowWeb',
  sampleTripFlowMobile: 'sampleTripFlowMobile',
  quickStartFlowWeb: 'quickStartFlowWeb',
  quickStartFlowMobile: 'quickStartFlowMobile',
  emptyTripFlowWeb: 'emptyTripFlowWeb',
  blankTripFlowWeb: 'blankTripFlowWeb',
  blankTripFlowMobile: 'blankTripFlowMobile',
};

export const tourName = {
  ...onboardingTourNames,
};

export const stepName = {
  TRIP_NAME_INTRO_STEP: 'TRIP_NAME-INTRO_STEP',
  MAP_SEARCH_BAR: 'MAP_SEARCH_BAR',
  ADD_TO_TRIP_BUTTON: 'ADD_TO_TRIP_BUTTON',
  FIRST_ACTIVITY_HOVER_INFO: 'FIRST_ACTIVITY_HOVER_INFO',
  FIRST_ACITVITY_OPEN_DETAILS: 'FIRST_ACITVITY_OPEN_DETAILS',
  FIRST_ACITVITY_OPEN_DETAILS_MOBILE: 'FIRST_ACITVITY_OPEN_DETAILS_MOBILE',
  TOUR_EXIT_BACK_TO_HOME: 'TOUR_EXIT_BACK_TO_HOME',
  ACTIVITY_ADDED_TO_TRIP_MOBILE: 'ACTIVITY_ADDED_TO_TRIP_MOBILE',
  HEADING_INTRO_STEP: 'HEADING_INTRO_STEP',
  SET_HEADING: 'SET_HEADING',
  ADD_TODO: 'ADD_TODO',
  TRIP_NAME_STEP: 'TRIP_NAME_STEP',
  ENTER_DESTINATION: 'ENTER_DESTINATION',
  ACTIVITIES_CLICK_TO_SEE_DETAILS: 'ACTIVITIES_CLICK_TO_SEE_DETAILS',
  ACTIVITIES_EXPAND_INITIAL_STEP: 'ACTIVITIES_EXPAND_INITIAL_STEP',
  EXPORT_TRIP_BUTTON: 'EXPORT_TRIP_BUTTON',
  EXPORT_TRIP_BUTTON_MOBILE: 'EXPORT_TRIP_BUTTON_MOBILE',
  COMMAND_BAR_INTRO_STEP: 'COMMAND_BAR_INTRO_STEP',
  COMMAND_BAR_SELECTORS_INFO: 'COMMAND_BAR_SELECTORS_INFO',
};

export const tripNameToSessionEndEvent = {
  sampleTripFlowWeb: EVENTS.ONBOARDING.PLAN_OB_ST.SESSION_END,
  sampleTripFlowMobile: EVENTS.ONBOARDING.PLAN_OB_ST.SESSION_END,
  quickStartFlowWeb: EVENTS.ONBOARDING.PLAN_OB_QS.SESSION_END,
  quickStartFlowMobile: EVENTS.ONBOARDING.PLAN_OB_QS.SESSION_END,
  emptyTripFlowWeb: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SESSION_END,
  blankTripFlowWeb: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SESSION_END,
  blankTripFlowMobile: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SESSION_END,
};

export const flowVariants = {
  default: 'default',
  commandBar: 'commandBar',
};

function IconLegend({ showDots = true, footer, header }) {
  return (
    <Stack direction="column" spacing={1}>
      {header && <Typography fontSize={12}>{header}</Typography>}
      <Stack alignItems="center" direction="row" spacing={1}>
        <DeleteOutlineRounded height={15} width={15} />
        <Typography fontSize={12}>Trashcan to delete</Typography>
      </Stack>
      {showDots && (
        <Stack alignItems="center" direction="row" spacing={1}>
          <AddRounded height={15} width={15} />
          <Typography fontSize={12}>Plus to add activity</Typography>
        </Stack>
      )}
      <Stack alignItems="center" direction="row" spacing={1}>
        <DragIndicatorRounded height={15} width={15} />
        <Typography fontSize={12}>Dots to move</Typography>
      </Stack>
      {footer && <Typography fontSize={12}>{footer}</Typography>}
    </Stack>
  );
}
const sampleTripFlowWebSteps = [
  {
    content: {
      default: {
        heading: 'Welcome to Pilot! Take a look at our sample UK trip.',
        description:
          'The + button lets you add destinations, flights, and other transport options to your itinerary',
        footer:
          'Check out the sample flight above to see how all the important details are filled in!',
        position: 'bottom',
        arrowStyles: {
          left: '8%',
        },
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
      },
      [flowVariants.commandBar]: {
        heading: 'Welcome to Pilot! Take a look at our sample UK trip.',
        description:
          'You can customize every part of your itinerary, your way. Pilot gives you the flexibility to plan trips exactly how you want.',
        footer:
          'Let’s explore what else you can do! Click anywhere on the planner to begin.',
        position: 'center',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
      },
    },
    selector: '#add-module-button-icon',
    skipMobile: false,
    position: {
      default: 'bottom',
      [flowVariants.commandBar]: 'center',
    },
    stepName: stepName.TRIP_NAME_INTRO_STEP,
  },
  {
    content: {
      heading: 'Pilot’s command bar',
      description:
        'Easily add flights, stays, and activities to your planner using the command bar.',
      footer: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          Try clicking on{' '}
          <GlobeIcon
            height={15}
            width={15}
            style={{ marginLeft: '4px', marginRight: '2px' }}
          />
          to add a destination!
        </span>
      ),
      position: 'top',
      phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
      containerStyles: {
        ml: '50%',
      },
    },
    featureFlags: [flags.UNIVERSAL_ADD_BAR],
    selector: '#command-bar',
    skipMobile: false,
    position: 'top',
    stepName: stepName.COMMAND_BAR_INTRO_STEP,
  },
  {
    content: {
      heading: 'Destination/Heading Selectors',
      description:
        'Use these selectors to focus on specific parts of your trip, making it easier to add items.',
      footer:
        'You can also drag & drop items from the command bar into your trip!',
      position: 'top',
      phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
      containerStyles: {
        ml: '10%',
      },
    },
    selector: '#destination-button-container',
    observe: ['#heading-button-container'],
    featureFlags: [flags.UNIVERSAL_ADD_BAR],
    highlightedSelectors: ['#heading-button-container'],
    skipMobile: false,
    position: 'top',
    stepName: stepName.COMMAND_BAR_SELECTORS_INFO,
  },
  {
    onStepSet: () => {
      document.querySelector('#planner')?.scrollTo(0, 0);
    },
    preOpen: () => {
      document.querySelector('#planner')?.scrollTo(0, 0);
    },
    content: {
      heading: "Let's add a new activity under London’s “must-dos”!",
      description: `Type "London Bridge" in the map’s search bar and select from the dropdown options. You should see the map card pop up below.`,
      footer: "You'll learn how to add it to your itinerary in the next step!",
      position: 'bottom',
      phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.SEARCH_BAR_CALLOUT,
    },
    selector: '#map-search-bar',
    waitForInteraction: true,
    disableIncrement: true,
    skipMobile: true,
    position: 'bottom',
    stepName: stepName.MAP_SEARCH_BAR,
  },
  {
    content: {
      default: {
        heading: 'Now add to your trip!',
        description: `Click the "Add to Trip" button to include "London Bridge" under your "Must-Dos."`,
        footer:
          'You can customize the pin on the map! Just click the dropdown on the map card.',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.ADD_TO_TRIP_CALLOUT,
      },
      [flowVariants.commandBar]: {
        heading: 'Now add to your trip!',
        description: `Click the "Add to Trip" button to include "London Bridge" under your "Must-Dos."`,
        footer:
          'You can customize the pin on the map! Just click the dropdown on the map card.',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.ADD_TO_TRIP_CALLOUT,
        arrowStyles: {
          left: '15%',
        },
      },
    },
    selector: '#add-to-trip-button-container',
    skipMobile: true,
    waitForInteraction: true,
    disableIncrement: true,
    position: 'top',
    stepName: stepName.ADD_TO_TRIP_BUTTON,
  },
  {
    onStepSet: () => {
      scrollIfNotVisibleBySelector('.activity-blade-container');
    },
    preOpen: () => {
      scrollIfNotVisibleBySelector('.activity-blade-container');
    },
    content: {
      heading: 'Hover to see the following icons:',
      description: (
        <IconLegend
          footer={`You can add activities in multiple ways on the planner. Try adding "London Zoo" to your "Must-Dos" by clicking the "+" button.`}
        />
      ),
      footer: 'Give it a try!',
      position: 'top',
    },
    selector: '.activity-blade-container',
    observe: '.activity-blade-container',
    mutationObservables: ['.activity-blade-container'],
    disableIncrement: true,
    waitForInteraction: true,
    skipMobile: true,
    position: 'top',
    stepName: stepName.FIRST_ACTIVITY_HOVER_INFO,
  },
  {
    onStepSet: () => {
      scrollIfNotVisibleBySelector('.activity-inlineblade-container');
    },
    preOpen: () => {
      scrollIfNotVisibleBySelector('.activity-inlineblade-container');
    },
    content: {
      heading: 'This is an activity blade.',
      description:
        'Here, you can add general notes or use the icons to attach files, dates, costs, or links!',
      footer: "When you're done click outside the activity panel to close.",
      position: 'top',
      phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.BLADE_EXPAND_CALLOUT,
    },
    selector: '.activity-inlineblade-container',
    waitForInteraction: false,
    position: 'top',
    stepName: stepName.FIRST_ACITVITY_OPEN_DETAILS,
  },
  {
    content: {
      heading: 'Congrats you’ve made it to the end!',
      description:
        "When you're ready, return to the dashboard to create your first trip.",
      footer: 'Let the planning begin!',
      position: 'bottom',
      arrowStyles: {
        left: '6%',
      },
      phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.SESSION_END,
    },
    selector: '#planner-back-button',
    waitForInteraction: false,
    position: 'bottom',
    stepName: stepName.TOUR_EXIT_BACK_TO_HOME,
  },
];

export const steps = {
  sampleTripFlowWeb: sampleTripFlowWebSteps,
  quickStartFlowWeb: [
    {
      content: {
        heading: 'This is a heading!',
        description:
          'Itinerary headings are the building blocks of your itinerary. They can contain as much or as little as you need.',
        footer: 'You can rename and reorganize them at any point.',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.HEADING_CALLOUT,
      },
      selector: '.blockHeader',
      position: 'top',
      stepName: stepName.HEADING_INTRO_STEP,
      action: () => {
        setTimeout(() => {
          document.querySelector('#planner')?.scrollBy(0, -3);
        }, 1000);
      },
    },
    {
      content: {
        heading: "Let's add a new activity under your trip heading!",
        description: `Use this box to find an attraction, restaurant, etc. you'd like to visit on your trip.”!`,
        footer: 'For now, type "Cafe" and select one from the dropdown.',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.SEARCH_BAR_CALLOUT,
      },
      selector: '#map-search-bar',
      waitForInteraction: true,
      skipMobile: true,
      position: 'bottom',
      stepName: stepName.MAP_SEARCH_BAR,
    },
    {
      content: {
        default: {
          heading: 'Add to your trip!',
          description: `Click the button to add this activity to your trip.`,
          footer:
            'Customize it by changing the map pin color and icons through the dropdown on this card.',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.ADD_TO_TRIP_CALLOUT,
        },
        [flowVariants.commandBar]: {
          heading: 'Add to your trip!',
          description: `Click the button to add this activity to your trip.`,
          footer:
            'Customize it by changing the map pin color and icons through the dropdown on this card.',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.ADD_TO_TRIP_CALLOUT,
          arrowStyles: {
            left: '15%',
          },
        },
      },
      selector: '#add-to-trip-button-container',
      skipMobile: true,
      waitForInteraction: true,
      disableIncrement: true,
      position: 'top',
      stepName: stepName.ADD_TO_TRIP_BUTTON,
    },
    {
      onStepSet: () => {
        scrollIfNotVisibleBySelector('.activity-blade-container');
      },
      preOpen: () => {
        scrollIfNotVisibleBySelector('.activity-blade-container');
      },
      content: {
        heading: 'Hover over an activity to see the following icons::',
        description: <IconLegend />,
        footer: `The '+' icon will allow you to add another new activity without needing to attach a location.`,
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.ACTIVITY_CALLOUT,
      },
      selector: '.activity-blade-container',
      observe: '.activity-blade-container',
      mutationObservables: ['.activity-blade-container'],
      disableIncrement: true,
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
      stepName: stepName.FIRST_ACTIVITY_HOVER_INFO,
    },
    {
      onStepSet: () => {
        scrollIfNotVisibleBySelector('.activity-inlineblade-container');
      },
      preOpen: () => {
        scrollIfNotVisibleBySelector('.activity-inlineblade-container');
      },
      content: {
        heading: 'This is an activity blade',
        description:
          'Here, you can add general notes or use the icons to attach files, dates, costs, or links!',
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.BLADE_EXPAND_CALLOUT,
      },
      selector: '.activity-inlineblade-container',
      waitForInteraction: false,
      position: 'top',
      stepName: stepName.FIRST_ACITVITY_OPEN_DETAILS,
    },
    {
      selector: '#export-trip-button',
      content: {
        heading: '⭐ Bonus: After you build - Export your trip!',
        description:
          "Export your itinerary in any form you like for easy access on the go or for sharing!﻿ You don't have to do this now, but just thought you'd like to know.",
        footer: "Happy Planning!﻿ You're a Pilot Pro!",
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.EXPORT_TRIP_CALLOUT,
      },
      waitForInteraction: false,
      position: 'bottom',
      stepName: stepName.EXPORT_TRIP_BUTTON,
    },
  ],
  quickStartFlowMobile: [
    {
      content: {
        heading: 'This is a heading!',
        description:
          'Itinerary headings are the building blocks of your itinerary. They can contain as much or as little as you need.',
        footer: 'You can rename and reorganize them at any point.',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.HEADING_CALLOUT,
      },
      selector: '.blockHeader',
      position: 'top',
    },
    {
      content: {
        heading: 'Tap an activity to see the following icons:',
        description: <IconLegend showDots={false} />,
        footer: '',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.ACTIVITY_CALLOUT,
      },
      selector: '#activity-blade-close',
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
      stepName: stepName.ACTIVITIES_CLICK_TO_SEE_DETAILS,
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },
    {
      content: {
        heading: 'Double tap to add details!',
        description:
          'Add an attachment, location, date, cost or link by tapping activities twice.',
        footer: '',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.ACTIVITY_CALLOUT,
      },
      selector: '#activity-blade-close',
      stepName: stepName.ACTIVITIES_EXPAND_INITIAL_STEP,
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
    },
    {
      content: {
        heading: 'This is an activity blade.',
        description:
          'Here, you can add general notes or use the icons to attach files, dates, costs, or links!',
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_QS.BLADE_EXPAND_CALLOUT,
      },
      selector: '.activity-inlineblade-container',
      waitForInteraction: false,
      position: 'top',
      stepName: stepName.FIRST_ACITVITY_OPEN_DETAILS_MOBILE,
    },
  ],
  sampleTripFlowMobile: [
    {
      content: {
        heading: 'Welcome to Pilot! Take a look at our sample UK trip.',
        description:
          'Click the + button to add destinations, flights, and other transport options to your itinerary.',
        footer:
          'Check out the sample flight above to see how all the important details are filled in!',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
      },
      selector: '#add-module-button',
      skipMobile: false,
      position: 'top',
    },
    {
      content: {
        heading: 'Add a new activity!',
        description: `Let's add a new activity under London’s “must-dos”!`,
        footer: `Click the "+Add Activity" button to and type "London Bridge". You’ll see a dropdown to select from.`,
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.SEARCH_BAR_CALLOUT,
      },
      selector: '.addTodo',
      waitForInteraction: true,
      stepName: stepName.ADD_TODO,
      skipMobile: true,
      position: 'bottom',
      action: () => {
        document.getElementsByClassName('addTodo')[0]?.scrollIntoView();

        setTimeout(() => {
          document.querySelector('#planner').scrollBy(0, -13);
        }, 500);
      },
    },
    {
      content: {
        heading: 'Congrats, your activity was added!',
        description: <IconLegend showDots={false} />,
        footer:
          "The '+ Activity' text below will allow you to add another new activity without needing to attach a location.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.ACTIVITY_CALLOUT,
      },
      selector: '#activity-blade-close',
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },
    {
      content: {
        heading: 'Double tap to add details!',
        description:
          'Add an attachment, location, date, cost or link by tapping activities twice.',
        footer: '',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.ACTIVITY_CALLOUT,
      },
      selector: '#activity-blade-close',
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },
    {
      content: {
        heading: 'This is an activity blade.',
        description:
          'Here, you can add general notes or use the icons to attach files, dates, costs, or links!',
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.BLADE_EXPAND_CALLOUT,
      },
      selector: '.activity-inlineblade-container',
      waitForInteraction: false,
      position: 'top',
    },
    {
      content: {
        heading: 'Congrats you’ve made it to the end!',
        description:
          "When you're ready, return to the dashboard to create your first trip.",
        footer: 'Let the planning begin!',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.SESSION_END,
      },
      selector: '#explore-tab-button',
      waitForInteraction: false,
      position: 'bottom',
      action: () => {
        document.getElementById('trip-cover-image')?.scrollIntoView({
          behavior: 'smooth',
        });
      },
    },
  ],
  // For a trip created with minimal details

  emptyTripFlowWeb: [
    {
      content: {
        heading: 'Welcome to Pilot!',
        description:
          'Begin by filling in a trip name. Try "Europe Backpacking Trip"',
        footer: 'Optional: Set dates to your trip!',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.TRIP_NAME_STEP,
      },
      selector: '#trip-name-input',
      waitForInteraction: true,
      stepName: stepName.TRIP_NAME_STEP,

      position: 'bottom',
    },
    {
      content: {
        heading: 'Next, fill out where you’d like to go!',
        description: 'Try clicking “destination and entering in "London"',
        footer: '',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ENTER_DESTINATION,
      },
      selector: {
        default: '.add-destination-button',
        [flowVariants.commandBar]: '#destination-button-globe',
      },
      highlightedSelectors: ['.add-destination-button-text'],
      waitForInteraction: true,
      position: 'top',
      stepName: stepName.ENTER_DESTINATION,
      action: () => {
        setTimeout(() => {
          document.querySelector('#planner').scrollBy(0, -100);
        }, 1000);
      },
    },
    {
      content: {
        heading:
          "Welcome to Pilot! This is how your planner looks - Let's set a heading!",
        description:
          'Try naming this segment by activity type like "Places to eat" or start chronologically with "Day 1"',
        footer: 'Optional: Set individual dates to your heading!',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SET_HEADING,
      },
      selector: '.blockHeader',
      waitForInteraction: true,
      position: 'top',
      stepName: stepName.SET_HEADING,
    },
    {
      content: {
        heading: "Let's add a new activity under your trip heading",
        description: `Use this box to find an attraction, restaurant, etc. you'd like to visit on your trip.`,
        footer: 'For now, type "Cafe" and select one from the dropdown.',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SEARCH_BAR_CALLOUT,
      },
      selector: '#map-search-bar',
      waitForInteraction: true,
      action: () => {
        setTimeout(() => {
          document.querySelector('#planner')?.scrollTo(0, 0);
        }, 100);
      },
      skipMobile: true,
      position: 'bottom',
      stepName: stepName.MAP_SEARCH_BAR,
    },
    {
      content: {
        default: {
          heading: 'Now add to your trip!',
          description: `Click the "Add to trip" button to open a menu for you to add it under the correct heading.`,
          footer:
            'Customize it! You can change the map pin’s color and icon by clicking the dropdown on this card.',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ADD_TO_TRIP_CALLOUT,
        },
        [flowVariants.commandBar]: {
          heading: 'Now add to your trip!',
          description: `Click the "Add to trip" button to open a menu for you to add it under the correct heading.`,
          footer:
            'Customize it! You can change the map pin’s color and icon by clicking the dropdown on this card.',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ADD_TO_TRIP_CALLOUT,
          // arrowStyles different for new map popup card
          arrowStyles: {
            left: '20%',
          },
        },
      },
      selector: '#add-to-trip-button-container',
      stepName: stepName.ADD_TO_TRIP_BUTTON,
      waitForInteraction: true,
      disableIncrement: true,
      skipMobile: true,
      position: 'top',
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },
    {
      content: {
        heading: 'Pilot’s command bar',
        description:
          'Additionally, you can easily add flights, stays, and activities to your planner using the command bar.',
        footer:
          'Pro tip: You can also use your keyboard! Try pressing “S” to add a stay.',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
        containerStyles: {
          ml: '50%',
        },
      },
      featureFlags: [flags.UNIVERSAL_ADD_BAR],
      selector: '#command-bar',
      skipMobile: false,
      position: 'top',
      stepName: stepName.COMMAND_BAR_INTRO_STEP,
    },
    {
      content: {
        heading: 'Destination/Heading Selectors',
        description:
          'Use these selectors to focus on specific parts of your trip, making it easier to add items.',
        footer:
          'You can also drag & drop items from the command bar into your trip!',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
        containerStyles: {
          ml: '10%',
        },
      },
      selector: '#destination-button-container',
      observe: ['#heading-button-container'],
      featureFlags: [flags.UNIVERSAL_ADD_BAR],
      highlightedSelectors: ['#heading-button-container'],
      skipMobile: false,
      position: 'top',
      stepName: stepName.COMMAND_BAR_SELECTORS_INFO,
    },
    {
      onStepSet: () => {
        scrollIfNotVisibleBySelector('.activity-blade-container');
      },
      preOpen: () => {
        scrollIfNotVisibleBySelector('.activity-blade-container');
      },
      content: {
        default: {
          heading: 'Hover to see the following icons:',
          description: (
            <IconLegend
              footer={
                "The '+ Activity' text below will allow you to add another new activity without needing to attach a location."
              }
            />
          ),
          footer: 'Try it now!',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ACTIVITY_CALLOUT,
        },
        [flowVariants.commandBar]: {
          heading: 'Let’s go back to your added activity',
          description: (
            <IconLegend header="Hover over an activity to see the following icons:" />
          ),
          footer: 'Try it now!',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ACTIVITY_CALLOUT,
        },
      },
      selector: '.activity-blade-container',
      // observe: '.activity-blade-container',
      // mutationObservables: ['.activity-blade-container'],
      disableIncrement: true,
      waitForInteraction: true,
      skipMobile: true,
      stepName: stepName.FIRST_ACTIVITY_HOVER_INFO,
      position: 'top',
    },
    {
      onStepSet: () => {
        scrollIfNotVisibleBySelector('.activity-inlineblade-container');
      },
      preOpen: () => {
        scrollIfNotVisibleBySelector('.activity-inlineblade-container');
      },
      content: {
        heading: 'Congrats, your activity was added!',
        description:
          'Here, you can add general notes or use the icons to attach files, dates, costs, or links!',
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.BLADE_EXPAND_CALLOUT,
      },
      selector: '.activity-inlineblade-container',
      waitForInteraction: false,
      position: 'top',
      stepName: stepName.FIRST_ACITVITY_OPEN_DETAILS,
    },
    {
      content: {
        heading: '⭐️ BONUS TIP: Only on Pilot - Plan With Your Friends',
        description:
          'Invite your trip mates to collab on this trip with you, the more the merrier! Everyone invited to the trip has access to all the planning tools and can contribute in real time. ﻿',
        foooter: "Happy Planning!﻿ You're a Pilot Pro!",
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.INVITE_FIRIENDS_CALLOUT,
      },
      selector: '#invite-friends-button-web',
      waitForInteraction: false,
      position: 'bottom',
    },
  ],
  blankTripFlowWeb: [
    {
      content: {
        heading: 'Welcome to Pilot!',
        description:
          'Begin by filling in a trip name. Try "Europe Backpacking Trip"',
        footer: 'Optional: Set dates to your trip!',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.TRIP_NAME_STEP,
        // arrowStyles: {
        //   left: '15%',
        // },
      },
      selector: '#trip-name-input',
      waitForInteraction: true,
      stepName: stepName.TRIP_NAME_STEP,

      position: 'bottom',
    },
    {
      content: {
        default: {
          heading: 'Next, fill out where you’d like to go!',
          description: 'Try clicking “destination and entering in "London"',
          footer: '',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ENTER_DESTINATION,
        },
        [flowVariants.commandBar]: {
          arrowStyles: {
            left: '10%',
          },
          containerStyles: {
            mt: '5%',
            ml: '-20px',
          },
          heading: 'Next, fill out where you’d like to go!',
          description: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Try clicking on{' '}
              <GlobeIcon
                height={15}
                width={15}
                style={{ marginLeft: '4px', marginRight: '2px' }}
              />
              to add a destination!
            </span>
          ),
          footer: '',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ENTER_DESTINATION,
        },
      },
      selector: {
        default: '.add-destination-button',
        [flowVariants.commandBar]: '#destination-button-globe',
      },
      highlightedSelectors: ['.add-destination-button-text'],
      waitForInteraction: true,
      position: 'top',
      stepName: stepName.ENTER_DESTINATION,
      action: () => {
        setTimeout(() => {
          document.querySelector('#planner').scrollBy(0, -100);
        }, 1000);
      },
    },
    {
      content: {
        heading: 'Next, set a heading.',
        description:
          'Try naming this segment by activity type like "Places to eat" or start chronologically with "Day 1"',
        footer: 'Optional: Set individual dates to your heading!',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SET_HEADING,
      },
      selector: '.blockHeader',
      waitForInteraction: true,
      position: 'top',
      stepName: stepName.SET_HEADING,
    },
    {
      content: {
        heading: "Let's add a new activity under your trip heading",
        description: `Type "Big Ben" in the search bar and select a place from the dropdown options.`,
        footer:
          "You'll learn how to add it to your itinerary in the next step!",
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SEARCH_BAR_CALLOUT,
      },
      selector: '#map-search-bar',
      waitForInteraction: true,
      action: () => {
        setTimeout(() => {
          document.querySelector('#planner')?.scrollTo(0, 0);
        }, 100);
      },
      skipMobile: true,
      position: 'bottom',
      stepName: stepName.MAP_SEARCH_BAR,
    },
    {
      content: {
        default: {
          heading: 'Now add to your trip!',
          description: `Click the "Add to trip" button to open a menu for you to add it under the correct heading.`,
          footer:
            'Customize it! You can change the map pin’s color and icon by clicking the dropdown on this card.',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ADD_TO_TRIP_CALLOUT,
        },
        [flowVariants.commandBar]: {
          heading: 'Now add to your trip!',
          description: `Click the "Add to trip" button to open a menu for you to add it under the correct heading.`,
          footer:
            'Customize it! You can change the map pin’s color and icon by clicking the dropdown on this card.',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ADD_TO_TRIP_CALLOUT,
          // arrowStyles different for new map popup card
          arrowStyles: {
            left: '20%',
          },
        },
      },
      selector: '#add-to-trip-button-container',
      stepName: stepName.ADD_TO_TRIP_BUTTON,
      waitForInteraction: true,
      disableIncrement: true,
      skipMobile: true,
      position: 'top',
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },
    {
      content: {
        heading: 'Pilot’s command bar',
        description:
          'Additionally, you can easily add flights, stays, and activities to your planner using the command bar.',
        footer:
          'Pro tip: You can also use your keyboard! Try pressing “S” to add a stay.',

        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
        containerStyles: {
          ml: '50%',
        },
      },
      featureFlags: [flags.UNIVERSAL_ADD_BAR],
      selector: '#command-bar',
      skipMobile: false,
      position: 'top',
      stepName: stepName.COMMAND_BAR_INTRO_STEP,
    },
    {
      content: {
        heading: 'Destination/Heading Selectors',
        description:
          'Use these selectors to focus on specific parts of your trip, making it easier to add items.',
        footer:
          'You can also drag & drop items from the command bar into your trip!',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_ST.HEADING_CALLOUT,
        containerStyles: {
          ml: '10%',
        },
      },
      selector: '#destination-button-container',
      observe: ['#heading-button-container'],
      featureFlags: [flags.UNIVERSAL_ADD_BAR],
      highlightedSelectors: ['#heading-button-container'],
      skipMobile: false,
      position: 'top',
      stepName: stepName.COMMAND_BAR_SELECTORS_INFO,
    },
    {
      onStepSet: () => {
        scrollIfNotVisibleBySelector('.activity-blade-container');
      },
      preOpen: () => {
        scrollIfNotVisibleBySelector('.activity-blade-container');
      },
      content: {
        default: {
          heading: 'Hover to see the following icons:',
          description: (
            <IconLegend
              footer={
                "The '+ Activity' text below will allow you to add another new activity without needing to attach a location."
              }
            />
          ),
          footer: 'Try it now!',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ACTIVITY_CALLOUT,
        },
        [flowVariants.commandBar]: {
          heading: 'Let’s go back to your added activity',
          description: (
            <IconLegend header="Hover over an activity to see the following icons:" />
          ),
          footer: 'Try it now!',
          position: 'top',
          phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ACTIVITY_CALLOUT,
        },
      },
      selector: '.activity-blade-container',
      // observe: '.activity-blade-container',
      // mutationObservables: ['.activity-blade-container'],
      disableIncrement: true,
      waitForInteraction: true,
      skipMobile: true,
      stepName: stepName.FIRST_ACTIVITY_HOVER_INFO,
      position: 'top',
      action: () => {
        setTimeout(() => {
          document.querySelector('#planner').scrollBy(0, -3);
        }, 1000);
      },
    },
    {
      onStepSet: () => {
        scrollIfNotVisibleBySelector('.activity-inlineblade-container');
      },
      preOpen: () => {
        scrollIfNotVisibleBySelector('.activity-inlineblade-container');
      },
      content: {
        heading: 'Congrats, your activity was added!',
        description:
          'Here, you can add general notes or use the icons to attach files, dates, costs, or links!',
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.BLADE_EXPAND_CALLOUT,
      },
      selector: '.activity-inlineblade-container',
      waitForInteraction: false,
      position: 'top',
      stepName: stepName.FIRST_ACITVITY_OPEN_DETAILS,
    },
    {
      content: {
        heading: '⭐️ BONUS TIP: Only on Pilot - Plan With Your Friends',
        description:
          'Invite your trip mates to collab on this trip with you, the more the merrier! Everyone invited to the trip has access to all the planning tools and can contribute in real time. ﻿',
        foooter: "Happy Planning!﻿ You're a Pilot Pro!",
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.INVITE_FIRIENDS_CALLOUT,
      },
      selector: '#invite-friends-button-web',
      waitForInteraction: false,
      position: 'bottom',
    },
  ],
  blankTripFlowMobile: [
    {
      content: {
        heading: 'Welcome to Pilot! Begin by filling in a trip name.',
        description: 'Try "Europe Backpacking Trip"',
        footer: 'Optional: Set dates to your trip!',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.TRIP_NAME_STEP,
        // arrowStyles: {
        //   left: '15%',
        // },
      },
      selector: '#trip-name-input',
      waitForInteraction: true,
      position: 'bottom',
      stepName: stepName.TRIP_NAME_INTRO_STEP,
    },
    {
      content: {
        heading: 'Next, fill out where you’d like to go!',
        description: 'Try clicking “destination and entering in "London"',
        footer: '',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ENTER_DESTINATION,
      },
      selector: '.add-destination-button',
      waitForInteraction: true,
      position: 'top',
    },
    {
      content: {
        heading: 'Next, set a heading.',
        description:
          'Try naming this segment by activity type like "Places to eat" or start chronologically with "Day 1"',
        footer: 'Optional: Set individual dates to your heading!',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SET_HEADING,
      },
      selector: '.blockHeader',
      waitForInteraction: true,
      stepName: stepName.SET_HEADING,
      position: 'top',
    },
    {
      content: {
        heading: "Let's add a new activity under your heading!",
        description: `Let's add a cafe to your itinerary.`,
        footer: `Click the "+ Activity" button to and type "Cafe". You’ll see a dropdown to select from.`,
        position: 'top',
        arrowStyles: {
          left: '15%',
        },
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SEARCH_BAR_CALLOUT,
      },
      selector: '#activity-blade-close',
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
      stepName: stepName.ADD_TODO,
    },
    {
      content: {
        heading: 'Congrats, your activity was added!',
        description: (
          <IconLegend
            showDots={false}
            footer="Tap on the activity once to reveal"
          />
        ),
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.BLADE_EXPAND_CALLOUT,
      },
      selector: '#activity-blade-close',
      skipMobile: true,
      position: 'top',
      stepName: stepName.ACTIVITY_ADDED_TO_TRIP_MOBILE,
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },

    {
      onStepSet: () => {
        document.querySelector('#planner').scrollTo(0, 0);
      },
      preOpen: () => {
        // scroll to the top of the page
        document.querySelector('#planner').scrollTo(0, 0);
      },
      content: {
        heading: '⭐️ BONUS TIP: Only on Pilot - Plan With Your Friends',
        description:
          'Invite your trip mates to collab on this trip with you, the more the merrier! Everyone invited to the trip has access to all the planning tools and can contribute in real time. ﻿',
        footer: "Happy Planning!﻿ You're a Pilot Pro!",
        position: 'bottom',
        arrowStyles: {
          left: '85%',
        },
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.INVITE_FIRIENDS_CALLOUT,
      },
      selector: '#invite-friends-button-web',
      waitForInteraction: false,
      position: 'bottom',
      stepName: stepName.EXPORT_TRIP_BUTTON_MOBILE,
    },
  ],
  emptyTripFlowMobile: [
    {
      content: {
        heading: 'Welcome to Pilot! Begin by filling in a trip name.',
        description: 'Try "Europe Backpacking Trip"',
        footer: 'Optional: Set dates to your trip!',
        position: 'bottom',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.TRIP_NAME_STEP,
        // arrowStyles: {
        //   left: '15%',
        // },
      },
      selector: '#trip-name-input',
      waitForInteraction: true,
      position: 'bottom',
      stepName: stepName.TRIP_NAME_INTRO_STEP,
    },
    {
      content: {
        heading: 'Next, fill out where you’d like to go!',
        description: 'Try clicking “destination and entering in "London"',
        footer: '',
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.ENTER_DESTINATION,
      },
      selector: '.add-destination-button',
      waitForInteraction: true,
      position: 'top',
    },
    {
      content: {
        heading: 'Next, set a heading.',
        description:
          'Try naming this segment by activity type like "Places to eat" or start chronologically with "Day 1"',
        footer: 'Optional: Set individual dates to your heading!',
        position: 'top',
      },
      selector: '.blockHeader',
      waitForInteraction: true,
      stepName: stepName.SET_HEADING,
      position: 'top',
    },
    {
      content: {
        heading: "Let's add a new activity under your heading!",
        description: `Let's add a cafe to your itinerary.`,
        footer: `Click the "+ Activity" button to and type "Cafe". You’ll see a dropdown to select from.`,
        position: 'top',
        arrowStyles: {
          left: '15%',
        },
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SEARCH_BAR_CALLOUT,
      },
      selector: '#activity-blade-close',
      waitForInteraction: true,
      skipMobile: true,
      position: 'top',
      stepName: stepName.ADD_TODO,
    },
    {
      content: {
        heading: 'Congrats, your activity was added!',
        description: (
          <IconLegend
            showDots={false}
            footer="Tap on the activity once to reveal"
          />
        ),
        footer: "When you're done click outside the activity panel to close.",
        position: 'top',
        phEvent: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.BLADE_EXPAND_CALLOUT,
      },
      selector: '#activity-blade-close',
      skipMobile: true,
      position: 'top',
      stepName: stepName.ACTIVITY_ADDED_TO_TRIP_MOBILE,
      // observe: '.MuiPopper-root',
      // mutationObservables: ['#search-location-popper'],
      // highlightedSelectors: ['.MuiPopper-root'],
      // observe: '.MuiPopper-root',
    },

    {
      onStepSet: () => {
        document.querySelector('#planner').scrollTo(0, 0);
      },
      preOpen: () => {
        // scroll to the top of the page
        document.querySelector('#planner').scrollTo(0, 0);
      },
      content: {
        heading: '⭐️ BONUS TIP: Only on Pilot - Plan With Your Friends',
        description:
          'Invite your trip mates to collab on this trip with you, the more the merrier! Everyone invited to the trip has access to all the planning tools and can contribute in real time. ﻿',
        footer: "Happy Planning!﻿ You're a Pilot Pro!",
        position: 'bottom',
        arrowStyles: {
          left: '85%',
        },
      },
      selector: '#invite-friends-button-web',
      waitForInteraction: false,
      position: 'bottom',
      stepName: stepName.EXPORT_TRIP_BUTTON_MOBILE,
    },
  ],
};

export default steps;
const transformStepContent = (step, variant) => {
  const transformedStep = { ...step };
  Object.keys(step).forEach((key) => {
    if (step[key] && step[key][variant]) {
      transformedStep[key] = step[key][variant];
    }
  });
  return transformedStep;
};

export const getSteps = (name, { filterWithFeatureFlags = true, variant }) => {
  const tourSteps = steps[name];
  if (!tourSteps) {
    return [];
  }
  if (!filterWithFeatureFlags) {
    return tourSteps;
  }
  const featureFlags = posthog.featureFlags.getFlags();
  const enabledFeatureFlags = featureFlags.filter((flag) =>
    posthog.isFeatureEnabled(flag)
  );

  return tourSteps
    .map((step) => transformStepContent(step, variant))
    .filter((step) => {
      // if featureFlags is empty, return all steps
      if (enabledFeatureFlags.length === 0) {
        return true;
      }
      // if featureFlags is not empty, return steps that have featureFlags or if step doesn't have featureFlags
      return (
        step.featureFlags?.some((flag) => enabledFeatureFlags.includes(flag)) ||
        !step.featureFlags
      );
    });
};
