const EVENTS = {
  GLOBAL_NAV: {
    LABS_TOGGLE_ON_CLICK: 'global_nav:labs_toggle_on_click',
    LABS_TOGGLE_OFF_CLICK: 'global_nav:labs_toggle_off_click',
  },
  TRIPS_DASHBOARD: {
    TRIP_CREATE_START: 'trips_dashboard:trip_create_start',
    TRIP_CLICK: 'trips_dashboard:trip_click',
    TRIP_SHARE_INVITE_SEND: 'trips_dashboard:trip_share_invite_send',
    TRIP_SHARE_LINK_COPY_CLICK: 'trips_dashboard:trip_share_link_copy_click',
    TRIP_SHARE_CLICK: 'trips_dashboard:trip_share_click',
    TRIP_DELETE: 'trips_dashboard:trip_delete',
  },
  TRIP_CREATE: {
    END: 'trip_create:end',
    INVITE_SEND: 'trip_create:invite_send',
    LOCATION_ADD: 'trip_create:location_add',
    COVER_PHOTO_UPDATE: 'trip_create:cover_photo_update',
    DATES_ADD: 'trip_create:dates_add',
    LABS_TOGGLE_ON_CLICK: 'trip_create:labs_toggle_on_click',
    QUICKSTART_FILTERS_APPLY_CLICK:
      'trip_create:quickstart_filters_apply_click',
    QUICKSTART_FILTERS_VIEW: 'trip_create:quickstart_filters_view',
  },
  TRIP_INVITE: {
    INVITE_START: 'trip_invite:invite_start',
    INVITE_ACCEPT_CLICK: 'trip_invite:invite_accept_click',
    INVITE_LATER_CLICK: 'trip_invite:invite_later_click',
  },
  PROFILE: {
    PROFILE_UPDATE: 'profile:profile_update',
  },
  SETTINGS: {
    ACCOUNT_DELETE: 'settings:account_delete',
  },
  TRIP_NOTES: {
    NOTE_CREATE: 'trip_notes:note_create',
  },
  TRIP_FILES: {
    FILE_CREATE: 'trip_files:file_create',
    FILE_NAME_UPDATE: 'trip_files:file_name_update',
    BOOKING_CLICK: 'trip_files:booking_click',
  },
  DISCOVER: {
    CITY_CLICK: 'discover:city_click',
  },
  DISCOVER_CITY: {
    WISHLIST_ADD: 'discover_city:wishlist_add',
    ACTIVITY_VIEW: 'discover_city:activity_view',
    ARTICLE_CLICK: 'discover_city:article_click',
  },
  WISHLIST: {
    ITEM_PHOTO_UPDATE: 'wishlist:item_photo_update',
    ITEM_NOTE_UPDATE: 'wishlist:item_note_update',
  },
  OFFERS: {
    DEALS_CLICK: 'offers:deals_click',
  },
  AUTH: {
    ACCOUNT_CREATE: 'auth:account_create',
    SIGNIN_END: 'auth:signin_end',
  },
  PLAN_HEADER: {
    COVER_PHOTO_UPDATE: 'plan_header:cover_photo_update',
    VISAS_REQUIREMENTS_VIEW: 'plan_header:visas_requirements_view',
  },
  PLAN_ADD: {
    LOCATION_START: 'plan_add:location_start',
    STAY_ADD: 'plan_add:stay_add',
    FLIGHT_ADD: 'plan_add:flight_add',
    BUS_TRAIN_ADD: 'plan_add:bus_train_add',
    OTHER_TRANSPORT_ADD: 'plan_add:other_transport_add',
  },
  PLAN_ACTIVITY: {
    ADD_START: 'plan_activity:add_start',
    FILE_VIEW: 'plan_activity:file_view',
    FILE_ADD: 'plan_activity:file_add',
  },
  PLAN_HEADING: {
    ADD_START: 'plan_heading:add_start',
  },
  PLAN_STAY: {
    ADD_CREATE: 'plan_stay:add_create',
    FILE_ADD: 'plan_stay:file_add',
    TRIP_LINK_CLICK: 'plan_stay:trip_link_click',
  },
  PLAN_FLIGHT: {
    ADD_CREATE: 'plan_flight:add_create',
    FILE_ADD: 'plan_flight:file_add',
  },
  PLAN_BUS_TRAIN: {
    ADD_CREATE: 'plan_bus_train:add_create',
    FILE_ADD: 'plan_bus_train:file_add',
  },
  PLAN_OTHER_TRANSPORT: {
    ADD_CREATE: 'plan_other_transport:add_create',
    FILE_ADD: 'plan_other_transport:file_add',
  },
  PLAN_MAP: {
    SEARCH_SUBMIT: 'plan_map:search_submit',
    STAYS_START: 'plan_map:stays_start',
    ACTIVITIES_START: 'plan_map:activities_start',
  },
  PLAN_MAP_CARD: {
    ACTIVITY_ADD: 'plan_map_card:activity_add',
    AUTO_ADD_ACTIVITY: 'plan_map_card:auto_activity_add',
    MANUAL_ACTIVITY_ADD: 'plan_map_card:manual_activity_add',
    ACTIVITY_DELETE: 'plan_map_card:activity_delete',
    ACTIVITY_DELETE_UNDO: 'plan_map_card:activity_delete_undo',
  },
  TRIP_NAV: {
    INVITE_SEND: 'trip_nav:invite_send',
    INVITE_LINK_COPY_CLICK: 'trip_nav:invite_link_copy_click',
  },
  ONBOARDING: {
    PLAN_OB_SCRATCH: {
      SESSION_START: 'plan_ob_scratch:session_start',
      TRIP_NAME_CALLOUT: 'plan_ob_scratch:trip_name_callout',
      ADD_DESTINATION: 'plan_ob_scratch:add_destination',
      ADD_HEADING: 'plan_ob_scratch:add_heading',
      SEARCH_BAR_CALLOUT: 'plan_ob_scratch:search_bar_callout',
      ADD_ACTIVITY_CALLOUT: 'plan_ob_scratch:add_activity_callout',
      ACTIVITY_CALLOUT: 'plan_ob_scratch:activity_callout',
      BLADE_EXPAND_CALLOUT: 'plan_ob_scratch:blade_expand_callout',
      ADD_DETAILS: 'plan_ob_scratch:add_details',
      SESSION_END: 'plan_ob_scratch:session_end',
    },
    PLAN_OB_QS: {
      SESSION_START: 'plan_ob_qs:session_start',
      HEADING_CALLOUT: 'plan_ob_qs:heading_callout',
      SEARCH_BAR_CALLOUT: 'plan_ob_qs:search_bar_callout',
      ADD_TO_TRIP_CALLOUT: 'plan_ob_qs:add_to_trip_callout',
      ACTIVITY_CALLOUT: 'plan_ob_qs:activity_callout',
      BLADE_EXPAND_CALLOUT: 'plan_ob_qs:blade_expand_callout',
      INVITE_FIRIENDS_CALLOUT: 'plan_ob_qs:invite_friends_callout',
      SESSION_END: 'plan_ob_qs:session_end',
    },
    PLAN_OB_ST: {
      SESSION_START: 'plan_ob_st:session_start',
      HEADING_CALLOUT: 'plan_ob_st:heading_callout',
      SEARCH_BAR_CALLOUT: 'plan_ob_st:search_bar_callout',
      ADD_TO_TRIP_CALLOUT: 'plan_ob_st:add_to_trip_callout',
      ACTIVITY_CALLOUT: 'plan_ob_st:activity_callout',
      BLADE_EXPAND_CALLOUT: 'plan_ob_st:blade_expand_callout',
      EXPORT_TRIP_CALLOUT: 'plan_ob_st:export_trip_callout',
      SESSION_END: 'plan_ob_st:session_end',
    },
  },
  EXPORT_PDF: {
    EXPORT_TRIP_CLOSE: 'export_pdf:export_trip_close',
    EXPORT_TRIP_OPEN: 'export_pdf:export_trip_open',
    EXPORT_TRIP_START: 'export_pdf:export_trip_start',
    EXPORT_TRIP_NOTES_TOGGLE: 'export_pdf:export_trip_notes_toggle',
    EXPORT_TRIP_IMAGES_TOGGLE: 'export_pdf:export_trip_images_toggle',
  },
  EXPLORE_ACTIVITIES: {
    ACTIVITY_SAVED_LIST_ADD: 'explore_activities:activity_saved_list_add',
    ACTIVITY_PLAN_ADD: 'explore_activities:activity_plan_add',
    ACTIVITY_CLICK: 'explore_activities:activity_click',
    FILTERS_APPLY_CLICK: 'explore_activities:filters_apply_click',
    SORT_UPDATE: 'explore_activities:sort_update',
    TRIP_LINK_CLICK: 'explore_activities:trip_link_click',
  },
  EXPLORE_STAYS: {
    STAY_SAVED_LIST_ADD: 'explore_stays:stay_saved_list_add',
    STAY_PLAN_ADD: 'explore_stays:stay_plan_add',
    STAY_CLICK: 'explore_stays:stay_click',
    FILTERS_APPLY_CLICK: 'explore_stays:filters_apply_click',
    SORT_UPDATE: 'explore_stays:sort_update',
    STAY_DEAL_CLICK: 'explore_stays:stay_deal_click',
  },
  EXPLORE_STAY_DETAIL: {
    PAGE_SCROLL_TO_BOTTOM_END: 'explore_stay_detail:page_scroll_to_bottom_end',
    ROOM_DEAL_BOOK_START: 'explore_stay_detail:room_deal_book_start',
    STAY_VIEW_DEALS_CLICK: 'explore_stay_detail:stay_view_deals_click',
    ROOM_ADD: 'explore_stay_detail:room_add',
    ROOM_REMOVE: 'explore_stay_detail:room_remove',
    CHECKOUT_START: 'explore_stay_detail:checkout_start',
  },
  CHECKOUT_GUEST_INFO: {
    FIRST_NAME_UPDATE: 'checkout_guest_info:first_name_update',
    LAST_NAME_UPDATE: 'checkout_guest_info:last_name_update',
    EMAIL_UPDATE: 'checkout_guest_info:email_update',
    PHONE_NUMBER_UPDATE: 'checkout_guest_info:phone_number_update',
    ADDITIONAL_INFO_UPDATE: 'checkout_guest_info:additional_info_update',
    NEXT_SUBMIT: 'checkout_guest_info:next_submit',
  },
  CHECKOUT_PAYMENT_INFO: {
    NEXT_SUBMIT: 'checkout_payment_info:next_submit',
    PAYMENT_ERROR: 'checkout_payment_info:payment_error',
  },
  CHECKOUT: {
    BOOKING_SUCCESS: 'checkout:booking_success',
    BOOKING_FAILED: 'checkout:booking_failed',
  },
  CHECKOUT_MANAGE_BOOKING: {
    CANCEL_BOOKING_START: 'checkout_manage_booking:cancel_booking_start',
    TOTAL_PAYMENT_INFO_EXPAND:
      'checkout_manage_booking:total_payment_info_expand',
    TOTAL_PAYMENT_INFO_COLLAPSE:
      'checkout_manage_booking:total_payment_info_collapse',
    SUPPORT_START: 'checkout_manage_booking:support_start',
  },
  CHECKOUT_CANCEL_BOOKING: {
    OTHER_OPTION_UPDATE: 'checkout_cancel_booking:other_option_update',
    REVIEW_CANCELLATION_CLICK:
      'checkout_cancel_booking:review_cancellation_click',
    CONFIRM_CANCELLATION_CLICK:
      'checkout_cancel_booking:confirm_cancellation_click',
    CANCEL_BOOKING_SUCCESS: 'checkout_cancel_booking:cancel_booking_success',
    CANCEL_BOOKING_ERROR: 'checkout_cancel_booking:cancel_booking_error',
  },
  EXPLORE_ACTIVITY_DETAIL: {
    SAVED_LIST_ADD: 'explore_activity_detail:saved_list_add',
    ACTIVITY_PLAN_ADD: 'explore_activity_detail:activity_plan_add',
    ACTIVITY_WEBSITE_CLICK: 'explore_activity_detail:activity_website_click',
    ACTIVITY_SOCIAL_MEDIA_CLICK:
      'explore_activity_detail:activity_social_media_click',
  },
  CHAT: {
    MESSAGE_SEND: 'chat:message_send',
  },
  PWYW: {
    CONTRIBUTE_OPEN: 'pwyw:contribute_open',
    PAYMENT_START: 'pwyw:payment_start',
    PAYMENT_SCREEN_BACK_BTN_CLICK: 'pwyw:payment_screen_back_btn_click',
    MODAL_CLOSED: 'pwyw:modal_closed',
    VIEW_THANK_YOU_MODAL: 'pwyw:view_thank_you_modal',
  },
  PLAN_UNIVERESAL_ADD: {
    AI_MENU_OPEN: 'plan_universal_add:ai_menu_open',
    AI_MENU_CLICK: 'plan_universal_add:ai_menu_click',
    SUGGEST_OPEN: 'plan_universal_add:suggest_open',
    SUGGEST_CLICK: 'plan_universal_add:suggest_click',
    SUGGEST_ACTIVITIES_OPEN: 'plan_universal_add:suggest_activities_open',
    SUGGEST_ACTIVITIES_CLICK: 'plan_universal_add:suggest_activities_click',

    SUGGEST_INITIAL_INTEREST_CLICK:
      'plan_universal_add:suggest_initial_interest_click',
    SUGGEST_INITIAL_INTEREST: 'plan_universal_add:suggest_initial_interest_',

    SUGGEST_INITIAL_SUBMIT: 'plan_universal_add:suggest_initial_submit',

    SUGGEST_INITIAL_LOAD_RESULTS:
      'plan_universal_add:suggest_initial_load_results',
    SUGGEST_RESULTS_INITIAL_ERROR:
      'plan_universal_add:suggest_results_initial_error',

    SUGGEST_RESULTS_SEE_DETAIL_CLICK:
      'plan_universal_add:suggest_results_see_detail_click',
    SUGGEST_RESULTS_SEE_DETAIL_CLICK_ACTIVITY:
      'plan_universal_add:suggest_results_see_detail_click_',

    SUGGEST_RESULTS_AUTO_ADD: 'plan_universal_add:suggest_results_auto_add',
    SUGGEST_RESULTS_MANUAL_ADD: 'plan_universal_add:suggest_results_manual_add',

    SUGGEST_RESULTS_AUTO_ADD_ACTIVITY:
      'plan_universal_add:suggest_results_auto_add_',
    SUGGEST_RESULTS_MANUAL_ADD_ACTIVITY:
      'plan_universal_add:suggest_results_manual_add_',

    SUGGEST_MAP_CARD_AUTO_ADD: 'plan_universal_add:suggest_map_card_auto_add',
    SUGGEST_MAP_CARD_MANUAL_ADD:
      'plan_universal_add:suggest_map_card_manual_add',
    SUGGEST_MAP_CARD_AUTO_ADD_ACTIVITY:
      'plan_universal_add:suggest_map_card_auto_add_',

    SUGGEST_SUBSEQUENT_INTEREST_CLICK:
      'plan_universal_add:suggest_subsequent_interest_click',
    SUGGEST_SUBSEQUENT_INTEREST:
      'plan_universal_add:suggest_subsequent_interest_',

    SUGGEST_SUBSEQUENT_SUBMIT: 'plan_universal_add:suggest_subsequent_submit',
    SUGGEST_SUBSEQUENT_LOAD_RESULTS:
      'plan_universal_add:suggest_subsequent_load_results',

    SUGGEST_SUBSEQUENT_RESULTS_ERROR:
      'plan_universal_add:suggest_subsequent_results_error',
  },
};

export default EVENTS;
