import { makeStyles } from '@mui/styles';
import {
  Box,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  Fade,
} from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { DirectionsBus, DirectionsCar } from '@mui/icons-material';

const useStyles = makeStyles({
  accommodationIconContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
  arrowDropDown: {
    transform: 'rotate(90deg)',
    height: 22,
    width: 22,
  },
});

const accommodations = [
  {
    icon: <img src="/images/Flight.svg" alt="Flight Icon" />,
    text: 'Flight',
    type: 'flight',
  },
  {
    icon: <DirectionsBus />,
    text: 'Bus/Train',
    type: 'bus',
  },
  {
    icon: <DirectionsCar />,
    text: 'Other',
    type: 'other',
  },
];

function TransportationButton({
  commonStyles,
  transportationAnchorEl,
  setTransportationAnchorEl,
  handleClickAway,
  handleAddTransportation,
  isTransportationClicked,
  isArrowClicked,
  handleArrowClick,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();

  const handleDropdwonClick = (event) => {
    handleArrowClick();
    setTransportationAnchorEl(
      transportationAnchorEl ? null : event.currentTarget
    );
  };

  return (
    <Grid item sx={{ ml: '-10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          title={
            <Box display="flex" alignItems="center">
              <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                Transportation
              </Typography>
              {isKeyboardShortcutEnabled && (
                <Box sx={commonStyles.shortcutKey}>T</Box>
              )}
            </Box>
          }
          enterDelay={500}
          TransitionComponent={Fade}
          placement="top"
          arrow
          componentsProps={commonStyles.componentsProps}>
          <Box
            sx={
              isTransportationClicked
                ? commonStyles.clickedStyle
                : commonStyles.hoverStyle
            }
            onClick={() => handleAddTransportation('flight')}>
            <img
              src="/images/Flight.svg"
              alt="Flight Icon"
              className={classes.accommodationIconContainer}
            />
          </Box>
        </Tooltip>
        <Tooltip
          title={
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              Select transportation
            </Typography>
          }
          enterDelay={500}
          TransitionComponent={Fade}
          placement="top"
          arrow
          componentsProps={commonStyles.componentsProps}>
          <Box
            sx={{
              ...(isArrowClicked
                ? {
                    ...commonStyles.arrowClickedStyle,
                    width: '24px',
                  }
                : {
                    ...commonStyles.arrowStyle,
                    '&:hover': {
                      backgroundColor: '#f4f4f4',
                      cursor: 'pointer',
                      borderRadius: 2,
                    },
                  }),
            }}
            onClick={handleDropdwonClick}>
            <ArrowRightRoundedIcon className={classes.arrowDropDown} />
          </Box>
        </Tooltip>
        <Popper
          open={Boolean(transportationAnchorEl)}
          anchorEl={transportationAnchorEl}
          placement="top-end"
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper>
              <List>
                {accommodations.map((accommodation, index) => {
                  return (
                    <ListItem
                      key={index}
                      sx={commonStyles.listItemContainer}
                      onClick={() =>
                        handleAddTransportation(accommodation.type)
                      }>
                      <ListItemIcon
                        sx={{
                          minWidth: '42px',
                        }}
                        className={classes.accommodationIconContainer}>
                        {accommodation.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={accommodation.text}
                        primaryTypographyProps={{ fontSize: '14px' }}
                        sx={commonStyles.listItemText}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Grid>
  );
}

export default TransportationButton;
